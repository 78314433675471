import MainService from './MainService'
var ClientService = {
    create(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'createClient';
            $data.method = 'post';
            $data.data = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    findAll(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'getClient';
            $data.method = 'get';
            $data.params = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
};
export default ClientService;