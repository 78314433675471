import MainService from './MainService'
var SafetyService = {
    create(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'createSafety';
            $data.method = 'post';
            $data.data = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    findAll(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'getSafety';
            $data.method = 'get';
            $data.params = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    getSafetyList(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'getSafetyList';
            $data.method = 'get';
            $data.params = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    findAllWithUrl(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'getSafetyWithUrl';
            $data.method = 'get';
            $data.params = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    findOne(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'getOneSafety/' + value.id
            $data.method = 'get';
            MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    }
};
export default SafetyService;