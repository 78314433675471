import React, { useState ,useEffect} from 'react';
import { ExclamationCircleOutlined, SafetyOutlined, FundProjectionScreenOutlined,CustomerServiceOutlined,FileDoneOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import ProjectService from '../../services/ProjectService';
const MobileMenu = ({closeDrawer}) => {
    const [project, setProject] = useState({})
    const history = useHistory();
    useEffect(() => {
        getProjectData()
      }, []);
      const getProjectData = () => {
        ProjectService.findAll().then(res => {
          let obj = {
            name: 'Project', url: 'projects',
            listData: res
          }
          setProject(obj)
        }).catch(e => {
    
          let obj = {
            name: 'Project', url: 'projects'
          }
          setProject(obj)
    
          console.log(e)
        })
      }
    const items = [
          {
            label: 'Service',
            key: '2',
            icon: <CustomerServiceOutlined className='menuIcon' />,
            url:'service',
            children: [
                  {
                    label: 'Engineering',
                    key: '2:1',
                    code:'engin',
                    url:'serviceView'
                  },
                  {
                    label: 'Construction',
                    key: '2:2',
                    code:'constract',
                    url:'serviceView'
                  },
                  {
                    label: 'Testing',
                    key: '2:3',
                    code:'test',
                    url:'serviceView'
                  },
                  {
                    label: 'Service & Maintenance',
                    key: '2:4',
                    code:'mainteance',
                    url:'serviceView'
                  },
            ],
          },
          {
            label: 'Safety',
            key: '3',
            icon: <SafetyOutlined className='menuIcon' />,
            url:'safety',
            children: [
                  {
                    label: 'Commitment to Safety',
                    key: '3:1',
                    url:'commitmentsafety',
                  },
                  {
                    label: 'Safety Training',
                    key: '3:2',
                    url:'safetytrainnig',
                  },
            ],
          },
          {
            label: 'Oes Standard',
            key: '4',
            icon: <FileDoneOutlined />,
            url:'standard',
          },
          {
            label: 'About Us',
            key: '5',
            icon: <ExclamationCircleOutlined className='menuIcon' />,
            url:'aboutus',
            children: [
                  {
                    label: 'About Al Gulnar Al Fawah Trad and Cont',
                    key: '5:1',
                    url:'aboutus'
                  },
                  {
                    label: 'History',
                    key: '5:2',
                    url:'aboutus'
                  },
            ],
          },
     
      ];
      const selectMenu = (menu) => {
        history.push(`/${menu.url}?code=${menu.code}`)
        closeDrawer()
      };
      const selectParentMenu = (menu) => {
        history.push(`/${menu.url}`)
        closeDrawer()
      }
      const goProject = (id) => {
        history.push(`/projectview?id=${id}`)
        closeDrawer()
      }
      const selectProjectMenu = () =>{
        history.push(`/projects`)
        closeDrawer()
      }
    return(
          <>
          
          <Menu
          className='mobileMenu'
      mode="inline"
      style={{border:'none'}}
    >
         <Menu.SubMenu 
                title={
                  <span className='menuList' onClick={() => selectProjectMenu()}>
                    {<FundProjectionScreenOutlined style={{marginRight:10}} />}
                    {project.name}
                  </span>
                }
              >
                 {project && project.listData && project.listData.length > 0 && project.listData.map(childItem => (
                  <Menu.Item onClick={() => goProject(childItem.id)}>
                    {childItem.subject || '  '}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
         {items.map((item,index) => <Menu.SubMenu 
                key={index}
                title={
                  <span className='menuList' onClick={() => selectParentMenu(item)}>
                    {<span style={{marginRight:10,fontSize:18}}>{item.icon}</span>}
                    {item.label}
                  </span>
                }
                className={item.url === 'standard' ? 'sub-menu-active':'mobileMenu'}
              >
                {item.children && item.children.map(childItem => (
                  <Menu.Item onClick={()=>selectMenu(childItem)}>
                    {childItem.label}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>)} 

    </Menu>
          </>
    )
}
export default MobileMenu;