import React from "react";
import { useEffect, useState } from 'react';
import { Button, Flex, Carousel } from 'antd';
import { Row, Col, Divider } from 'antd';
import styled from "styled-components";
import { useHistory } from 'react-router-dom';
// Components
import thirdImage from '../../../assets/img/slider-3.jpg'
import ProjectService from '../../../services/ProjectService';
import Icon, { NotificationOutlined } from '@ant-design/icons';
import SafetyService from "../../../services/SafetyService";



export default function Safety() {
    const history = useHistory();
    const [safetyData, SetSafetyData] = useState({})

    useEffect(() => {
        getProjectDataWithUrl();
        getSafety()
    }, []);
    const getProjectDataWithUrl = () => {
        ProjectService.findAllWithUrl()
            .then((res) => {
                setAllappsData(res);
            })
            .catch((e) => {
                console.log(e);
            });
    };
      
  const getSafety = () => {
    SafetyService.findAll().then(res => {
        console.log(res,"uuuuuuuuueees")
      SetSafetyData(res)
    }).catch(e => {
      SetSafetyData({})

      console.log(e)
    })
  }
    const [AllappsData, setAllappsData] = useState([])

    const listData = [
        { name: 'Project', subName: 'Palisades Nuclear Plant' },
        { name: 'Type', subName: 'Power Generation' },
        { name: 'Location', subName: 'Covert, Michigan' },
        { name: 'Role', subName: 'Electrical Contractor' },
    ]
    return (
        <Wrapper id="projects">
            <div className="">
                <div style={{ backgroundImage: "url(" + `${thirdImage}` + ")", height: 450, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>
                    <div style={{ width: '100%', height: '100%', background: 'black', opacity: 0.5, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff' }}>
                        <h1 className="corousal-header"><strong>SAFETY</strong></h1>
                    </div>
                    {/* <div className="col-md-6 textRight">
                <img src={bannerImg} className="img-fluid allapps-banner"/>
              </div> */}
                </div>
                <div className="container">
                    <Divider style={{ borderWidth: 5, borderColor: '#c2c2c2' }} />
                    <div className="SafetyContent">
                        <div className="safetyButton"><NotificationOutlined className="notficationIcon" /><span className="hoveSafety">REPORT A SAFETY EVENT</span></div>
                    </div>
                    <Divider style={{ borderWidth: 5, borderColor: '#c2c2c2' }} />
                    <h2><b>Safety starts long before the work starts.</b></h2>
                    <p style={{ fontSize: 16, marginTop: 15 }}>
                        S.A.F.E. means Staying Accident Free Everyday. Our S.A.F.E. program represents Al Gulnar Al Fawah Trad and Cont’s commitment to zero injuries on our jobsites. Through a dedication to safety training, communication, and the continual integration of new safety systems and guidelines, Newkirk has maintained a strong safety record throughout our long history. Our written safety plan follows Utility, OSHA and DOT guidelines and covers a wide range of topics. More importantly, our management and employees recognize that a safety mindset is the most critical characteristic to being a true construction professional.
                    </p>
                    <Row style={{marginTop:40}}>
                    {safetyData && safetyData.length > 0 ? safetyData.map((safetyItem, index) => (
                        <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <h3>{safetyItem?.subject||''}</h3>
                            <p style={{ fontSize: 16, marginTop: 8, marginBottom: 10 }}>
                                {safetyItem?.subject||''}
                            </p>
                            <ul style={{ listStyle: 'none' }}>
                                <List className="mb-5 activeLink">
                                    {/* Assuming you have a URL for the safety letter */}
                                    <a onClick={() => { history.push(`viewSafety?id=${safetyItem.id}`) }} className='listName'>Read our {safetyItem.subject}</a>
                                </List>
                            </ul>
                        </Col>
                    )) : <></>}
                    </Row>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.section`
width: 100%;
text-align: left;
`;
const List = styled.li`
    color:#DB373E;
    font-weight: bold;
    position: relative;
    right: 5px;
`;
const HeaderInfo = styled.div`
  color:#000;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
