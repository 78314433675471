import { Row, Col, Carousel } from 'antd';
import rhsimg from "../../../assets/img/banner3.jpg";
import secondImage from '../../../assets/img/banner2.jpg'
import thirdImage from '../../../assets/img/slider-4.jpg'
import styled from "styled-components";
import developerImg from "../../../assets/img/rightArrow.png";
import { useHistory } from 'react-router-dom';
export default function SafetyTrainnig() {
    const history = useHistory();
    const FirstList = [
        { label: 'Safety Orientation',},
        { label: 'Safety Training Matrix-tracks training needs'},
        { label: 'OSHA 10 and 30 hour training for field supervisors/foremen'},
        { label: 'In-house training and out-sourced training on additional topics'},
        { label: 'Annual Training and Re-certifications'},
        { label: 'Weekly Toolbox Talks'},
    ]
    return (
        <>
         <div style={{backgroundImage:"url("+`${thirdImage}`+")",height:400, backgroundSize: 'cover',backgroundRepeat: 'no-repeat', }}>
               <div style={{width:'100%',height:'100%',background:'black',opacity:0.5,display:'flex',justifyContent:'center',alignItems:'center',color:'#fff'}}>
                <h1 className="corousal-header"><strong>SAFETY TRAINING</strong></h1>
                </div>
                {/* <div className="col-md-6 textRight">
                <img src={bannerImg} className="img-fluid allapps-banner"/>
              </div> */}
            </div>
            <div className="container mb-3  my-4" >
                <p style={{ fontSize: 20, marginTop: 15 }}>Training is a critical piece of the S.A.F.E. program and it begins with hiring the most qualified technicians who have undergone a rigorous 4-5 year classroom based and hands-on apprenticeship program. This training is supplemented with Newkirk's own in-house training programs which cover a variety of safety topics including general safety, equipment-specific training, and duty-specific training.</p>
                <Row style={{ padding: 25, paddingLeft: 50 }}>
                {FirstList.map((item,index) => <Col key={index} xs={24} sm={24} md={24} lg={24} xl={24}>
                        <ul>
                            <List>
                                <a  >{item.label}</a>
                            </List>
                        </ul>
                      {index === 3 && <UList>
                        <List1> <a  >First Aid, CPR, Fire Safety, Forklift, PPE, Hazcom, Emergency Procedures, Equipment training, Hi-lift training</a></List1>
                       </UList>}
                    </Col>)}
                </Row>
            </div>
        </>
    )
}
const List = styled.li`
    color:#000000;
    padding: 4px 0;
    font-size:20px
`;
const List1 = styled.li`
    color:#000000;
    padding: 4px 0;
    font-size:17px;
`;
const UList = styled.ul`
list-style: circle;
margin-left: 50px;
`;