import { Row, Col, Carousel } from 'antd';
import React, { useEffect, useState } from "react";
import rhsimg from "../../../assets/img/banner3.jpg";
import secondImage from '../../../assets/img/banner2.jpg'
import thirdImage from '../../../assets/img/slider-2.jpg'
import styled from "styled-components";
import developerImg from "../../../assets/img/rightArrow.png";
import { useHistory } from 'react-router-dom';
import BackendService from '../../../services/Service';
import DocumentService from '../../../services/DocumentService';


export default function Service() {
	const history = useHistory();

    const [serviceData, SetServiceData] = useState({})

	useEffect(() => {
		getService()
	}, []);
		const FirstList = [
			{ label: 'Engineering',code:'engin' },
			{ label: 'Construction',code:'constract' },
			{ label: 'Testing',code:'test' },
			{ label: 'Service & Maintenance',code:'mainteance' },
		]
		const selectService = (item) =>{
			history.push(`/viewService?id=${item.id}&type=view`)
		}

		const getService = () => {
			BackendService.findAll().then(async res => {
				for (let i = 0; i < res.length; i++) {
					res[i].imageData = await getImageData(res[i].logo_id);
				}
				SetServiceData(res)
			}).catch(e => {
                SetServiceData([])
				console.log(e)
			})
		}
		const getImageData = async (id) => {
			if (!id) {
				return null;
			}
			const imageData = await DocumentService.imageProfile({ id: parseInt(id) });
			return imageData;
		};
		return (
			<>
				<div style={{backgroundImage:"url("+`${thirdImage}`+")",height:450, backgroundSize: 'cover',backgroundRepeat: 'no-repeat', }}>
					<div style={{width:'100%',height:'100%',background:'black',opacity:0.5,display:'flex',justifyContent:'center',alignItems:'center',color:'#fff'}}>
						<h1 className="corousal-header"><strong>Service</strong></h1>
						</div>
						{/* <div className="col-md-6 textRight">
						<img src={bannerImg} className="img-fluid allapps-banner"/>
					</div> */}
				</div>
				<div className="container  my-5 content-wrapper" >
					<h3>By offering more services, we give you greater control.</h3>
					<p style={{ fontSize: 16, marginTop: 15 }}>Al Gulnar Al Fawah Trad and Cont offers truly comprehensive electrical design and construction services for all types of projects both large and small. Partnering with our internal, full-service engineering subsidiary, Theka Engineering, we are able to provide turnkey projects from complete design through construction and commissioning. We self-perform over 90% of our work to maintain control over safety, quality, schedule and budget. The company performs general contracting and civil construction on many projects in addition to electrical work.</p>
					<Row style={{ padding: 25, paddingLeft: 50 }}>
					{serviceData && serviceData.length > 0 && serviceData.map(item => (
								<Col key={item.id} xs={24} sm={12} md={10} lg={12} xl={12}>
									<List onClick={() => selectService(item)} className="mb-3 activeLink">
										<img className="linkList" src={item.imageData ? item.imageData : developerImg} alt="Avatar" style={{ width: '13px', height: '13px' }} />
										<a className="listName">{item.subject}</a>
									</List>
								</Col>
							))}
					</Row>
				</div>
			</>
		)
}
const List = styled.li`
	color:#DB373E;
	padding: 4px 0;
	font-weight: bold;
`;
