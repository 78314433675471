import { Row, Col, Carousel } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import enginImage from '../../../assets/banner/banner5.jpg'
import constructionImage from '../../../assets/banner/banner4.jpg'
import testImage from '../../../assets/banner/banner9.jpg'
import serviceImage from '../../../assets/banner/banner1.jpg'
export default function ServiceView() {
    const location = useLocation();
    let pathname = location.search.split('code=')[1]
    useEffect(() => {
        console.log(pathname, 'name');
    }, []);
    const EngineeringData = [
        {
            title: 'Engineering',
            description: 'AGAF admires high dedication for the quality and good performance to be matchless among other electrical contractors.',
            list: [
                {
                    label: 'A.	ESTIMATION:',
                    description: `
                AGAF estimators use their specialized knowledge to determine the project is worth doing which involves project scope, quantity take-off, pricing, 
                overhead and profit and finalizing the estimate.It also involves analyzing
                 the risks for the project estimation and schedules. The primary estimation includes the capital cost, budgeting and forecasting.`,
                },
                {
                    label: 'B.	PROCUREMENT:', description: `AGAF using powerful project-based software, ODOO to help clients thrive in challenging times.
                 ODOO is clean and fast, as modern user interface for purchase tenders, control vendor bills and done the blanket order agreement.  
                 It offers a practical, cost-effective, flexible and confidence-building tool that streamlines the procurement process for project 
                 management and procurement division.This procurement process involves Tendering, Bid evaluation, purchasing, contract administration and finally expediting.`,
                },
                {
                    label: 'C.	PROJECT CONTROLS:', description: `It provides meticulous approach to managing risk.AGAF preemptively identifying risks, 
                monitoring risk continuously, and developing contingency plans to address and mitigate issues, it becomes possible to reduce impact 
                on budget and schedule. This project control effectively helps AGAF to prevent some risks from happening in the future.It includes Cost 
                estimating and project finance, planning, scheduling, Project reporting, Project accounting, Material control, Risk and decision management.We 
                aim to Successful delivery of projects from conceptual throughout start-up in every phase’s in-depth understanding of project risk, finance, planning, 
                cost, and schedule.Thus it provides information that allows AGAF project managers to make informed and timely decisions that prevent project risks.
                 From project initiation to completion, they shall keep things on track and within scope`,
                },
                {
                    label: 'D.	PROJECT MANAGEMENT:', description: `We, Al Gulnar consider the standardized approach to track the projects 
                from start to finish.AGAF believes the successful of every project goes through process of initiation, planning, execution, 
                monitoring and controlling.AGAF creates the vision statement which chooses the appropriate frame work and select the team by 
                define the scope of the project. We clearly identify the project risks and deliver the expected business value and return on investment.`,
                },
            ]
        }
    ]
    const constructionData = [
        {
            title: 'Construction',
            description: 'Over the past 60 years, Al Gulnar Al Fawah Trad and Cont has installed virtually every type of electrical system from small projects to demanding ones requiring the management of large crews and complex logistical challenges. Newkirk is experienced in the construction of a wide range of applications for both local and multi-national clients. On electrical-intensive projects, Newkirk frequently provides general contracting and civil construction as well, providing a turnkey project.',
            list: [
                {
                    label: 'A.	SUBSTATION:',
                    description: `AGAF is engaged in the construction of electrical substations in OMAN.
                    Primary and Distribution substations serve as critical nodes in the electrical systems, facilitating the distribution of power to communities. Behind the seamless functioning of these substations lies the expertise and dedication of our team. From construction to maintenance and modernization, we play a pivotal role in ensuring the reliability, efficiency, and safety of distribution substations.
                    We carry out the Outdoor Distribution substations up to 6 MVA capacities. Primary and Distribution substations house a myriad of equipment, including transformers, circuit breakers, switches, and protective devices. We are responsible for selecting the appropriate equipment based on the specific requirements of the substation and integrating them seamlessly into the overall infrastructure.
                    Our in-depth knowledge of electrical systems ensures optimal performance, efficiency, and reliability of the substation. SCADA control provisions for the remote and smooth operations gives the fast and accurate information about the energy system requires significant resources, so modern electrical substations use new automated solutions.
                    Safety is paramount in the operation of distribution substations. AGAF meticulously adhere to safety protocols and regulations to protect workers, the public, and the environment. They implement robust safety measures, conduct thorough risk assessments, and provide comprehensive training to personnel working in and around substations. By prioritizing safety at every stage, they mitigate potential hazards and ensure a secure operating environment.
                    We expertise in the modernization of substations, implementing upgrades to enhance efficiency, capacity, and reliability while integrating advanced technologies such as smart solutions.
                    In times of emergencies, such as equipment failures or natural disasters, we are at the forefront of restoring functionality to distribution substations. Their rapid response teams are equipped with the necessary expertise and resources to assess damages, prioritize repairs, and expedite restoration efforts. Their swift action helps minimize disruptions to power supply, ensuring continuity of service for communities relying on the substation.
                    Advancements in electrical engineering results, Indoor substation is built in densely populated areas for safe and efficient operation. We admire with the innovative technologies and approaches to the construction of electrical substations, which have already demonstrated success in some of our completed projects.
`,
                },
                {
                    label: 'B.	ELECTRICAL OVERHEAD LINES:',
                    description: `In the intricate web of power distribution, overhead lines stand as vital conduits, delivering electricity to homes, businesses, and industries. AGAF performing the seamless operation of these networks relies on the expertise and diligence. From installation to maintenance and beyond, we play a crucial role in ensuring the reliability and efficiency of overhead power distribution systems. We are dealing with overhead lines up to 33 KV.
                    It comprises of Overhead line conductors secured on the insulators over the wooden or concrete poles. AGAF follows the OES 01 standardized for both 11 and 33 KV Overhead lines.  
                    We are instrumental in the installation of overhead lines, starting from the initial planning stages to the final implementation. Our team expertise encompasses the design of distribution routes, selection of appropriate materials, and adherence to safety regulations. By leveraging 
                    our technical prowess and ensure the seamless integration of overhead lines into existing infrastructure while minimizing disruptions to communities and environments.
                    It is more challenging and calls for taking many factors into consideration.  In actual practice our team is dedicated to take care of all the external and other factors like climate, exposure to heat, wear and tear etc. AGAF truly involves their team own safety and give quality based services to our significant clients. We sincerely guide and keep you updated throughout the installation process.
                    AGAF has executed numerous projects in medium voltage distribution lines successfully in Oman. The evolution of technology necessitates the continuous upgrading and modernization of power distribution infrastructure. AGAF plays a pivotal role in implementing upgrades to enhance the efficiency, reliability, and capacity of overhead lines.
`,
                },
                {
                    label: 'C.	UNDER GROUND CABLES',
                    description: `Beneath the surface of our cities and towns lies a network of underground cables that form the lifeline of modern electrical infrastructure. From powering homes to energizing businesses, underground cables play a pivotal role in distributing electricity reliably and efficiently. 
                    AGAF provides the services in underground cable works, highlighting contributions to the reliability, safety, and resilience of electrical distribution systems.
                    We collaborate with our clients and regulatory authorities to develop comprehensive plans that account for factors such as load requirements, terrain conditions, and environmental considerations. Our expertise in cable routing, trenching techniques and material selection ensures the efficient and sustainable deployment of underground cable networks.
                    The installation of underground cables demands precision and expertise to navigate the complexities of urban landscapes and subterranean obstacles. 
                    AGAF oversees the installation process, coordinating excavation, cable laying, splicing, and termination activities with meticulous attention to detail.
                     By leveraging specialized equipment and techniques, they minimize disruptions to communities and infrastructure while adhering to safety regulations and quality standards. 
                     Upon completion, we conduct rigorous testing and commissioning procedures to verify the integrity and functionality of the underground cable system.
                    `,
                },
            ]
        }
    ]
    const testingData = [
        {
            title: 'Testing',
            description: 'Al Gulnar Al Fawah Trad and Cont offers startup and commissioning services to provide customers with confidence in the integrity of their systems and equipment. Startup services ensure proper operation of equipment and systems from their initial operational stages, as well as providing stringent safety standards and control during the critical startup process. Comprehensive testing procedures calibrate equipment and systems to meet the exact specifications and requirements of every project, from systems to components.',
            list: [
                { label: 'Commission and Test Electrical Systems through 765kV' },
                { label: 'Transformer Oil Analysis / Sampling' },
                { label: 'Acceptance Testing' },
                { label: 'Electrical System Infrared Surveys' },
                { label: 'Instrument & Protective Device Calibration' },
            ]
        }
    ]
    const maintanceData = [
        {
            title: 'Service & Maintenance',
            description: 'Al Gulnar Al Fawah Trad and Cont s dedicated Service Division offers comprehensive electrical service and troubleshooting, as well as long-term maintenance programs for a wide range of electrical applications. Newkirk s Service technicians complete a rigorous four- to five-year training program, and are state-licensed electricians. So projects are completed right the first time, saving time and money in the long run. Newkirk performs service work for all types of facilities from small projects, to large-scale ones.',
            list: [
                { label: 'Cellular Dispatched Emergency Service 24/7' },
                { label: 'High, Medium and Low Voltage Expertise' },
                { label: 'Power Line Maintenance and Storm Restoration' },
                { label: 'Cellular/Radio Towers, Shelters and Grounds' },
                { label: 'Bucket Truck Services' },
                { label: 'Complete Electrical Testing' },
                { label: 'Machine Repair' },
            ]
        }
    ]
    return (
        <>

            <div style={{ backgroundImage: "url(" + `${pathname === 'engin' ? enginImage : pathname === 'constract' ? constructionImage : pathname === 'test' ? testImage : serviceImage}` + ")", height: 350, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>
                <div style={{ width: '100%', height: '100%', background: 'black', opacity: 0.5, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff' }}>
                    <h1 className="corousal-header"><strong>
                        {pathname === 'engin' ? 'Engineering' : pathname === 'constract' ? 'Construction' : pathname === 'test' ? 'Testing' : 'Service & Maintenance'}
                    </strong></h1>
                </div>
                {/* <div className="col-md-6 textRight">
                <img src={bannerImg} className="img-fluid allapps-banner"/>
              </div> */}
            </div>
            <div className='content-wrapper py-5 pt-5'>
                {pathname === 'engin' && EngineeringData.map((item, index) => <div key={item.title} className="container  my-2" >
                    <h2><strong>{item.title}</strong></h2>
                    <p style={{ fontSize: 15, marginTop: 15, fontWeight: 500 }}>{item.description}</p>
                    <Row style={{ padding: 25, paddingLeft: 50 }}>
                        {item.list.map((k, index) => <Col key={index} xs={24} sm={24} md={24} lg={24} xl={24}>
                            <h6><strong>{k.label}</strong></h6>
                            <ul style={{ listStyle: 'unset', marginLeft: 40, marginTop: 20, marginBottom: 20 }}>
                                <li style={{ fontSize: 15, fontWeight: 500 }}><span >{k.description}</span></li>
                            </ul>
                        </Col>)}
                    </Row>

                </div>)}
                {pathname === 'constract' && constructionData.map((item, index) => <div key={index} className="container  my-2" >
                    <h2><strong>{item.title}</strong></h2>
                    <p style={{ fontSize: 15, marginTop: 15, fontWeight: 500 }}>{item.description}</p>
                    <Row style={{ padding: 25, paddingLeft: 50 }}>
                        {item.list.map((k, index) => <Col key={index} xs={24} sm={24} md={24} lg={24} xl={24}>
                            <h6><strong>{k.label}</strong></h6>
                            <ul style={{ listStyle: 'unset', marginLeft: 40, marginTop: 20, marginBottom: 20 }}>
                                <li style={{ fontSize: 15, fontWeight: 500, lineHeight: 2, }}><span >{k.description}</span></li>
                            </ul>
                        </Col>)}
                    </Row>

                </div>)}
                {pathname === 'test' && testingData.map((item, index) => <div key={index} className="container  my-2" >
                    <h2><strong>{item.title}</strong></h2>
                    <p style={{ fontSize: 15, marginTop: 15, fontWeight: 500 }}>{item.description}</p>
                    <Row style={{ padding: 25, paddingLeft: 50 }}>
                        {item.list.map((k, index) => <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <ul style={{ listStyle: 'unset' }}>
                                <li style={{ fontSize: 15, fontWeight: 500, lineHeight: 2, }}><span >{k.label}</span></li>
                            </ul>
                        </Col>)}
                    </Row>

                </div>)}
                {pathname === 'mainteance' && maintanceData.map((item, index) => <div key={index} className="container  my-2" >
                    <h2><strong>{item.title}</strong></h2>
                    <p style={{ fontSize: 15, marginTop: 15, fontWeight: 500 }}>{item.description}</p>
                    <Row style={{ padding: 25, paddingLeft: 50 }}>
                        {item.list.map((k, index) => <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <ul style={{ listStyle: 'unset' }}>
                                <li style={{ fontSize: 15, fontWeight: 500, lineHeight: 2, }}><span >{k.label}</span></li>
                            </ul>
                        </Col>)}
                    </Row>

                </div>)}
            </div>
        </>
    )
}