import MainService from './MainService'
var PolicyService = {
    create(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'createPolicy';
            $data.method = 'post';
            $data.data = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    findAll(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'getPolicy';
            $data.method = 'get';
            $data.params = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    getPolicyList(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'getPolicyList';
            $data.method = 'get';
            $data.params = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    findAllWithUrl(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'getPolicyWithUrl';
            $data.method = 'get';
            $data.params = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    findOne(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'getOnePolicy/' + value.id
            $data.method = 'get';
            MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    }
};
export default PolicyService;