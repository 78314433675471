import MainService from './MainService'
var EmailService = {
    sendEmail(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'sendEmail';
            $data.method = 'post';
            $data.data = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error);
                })
        });

    }
};
export default EmailService;