import React from "react";
import styled from "styled-components";
import Fade from 'react-reveal/Fade';
import { Carousel, Row, Col, Button } from 'antd';
// Assets

export default function Developer() {
  return (
    <Wrapper id="developer">
      <Heading> Al Gulnar Al Fawah Trad and Cont. LLC</Heading>
      <div className="content-wrapper">
    <div className="row">
      <div className="col-md-9">
        <div className="mb-4">
          <p className="font18">Established with a vision to revolutionize the electrical service sector, Al Gulnar Al Fawah has been at the forefront of innovation and excellence since 2007. With a strong commitment to quality, safety, and efficiency, we have garnered a reputation for reliability and professionalism in every project we undertake.
          </p>
        </div>
        <div className="mb-4">
          <p className="font18">With years of industry experience and a commitment to excellence, we have established ourselves as a trusted name in the field of electrical contracting where innovation meets efficiency in the realm of electrical solutions.
          </p>
        </div>
        <div className="mb-4">
          <p className="font18">Our portfolio encompasses a wide array of services including Electrical Design and engineering, Installation and integration, Energy management solutions, maintenance and support, custom solutions, emergency services.
          </p>
        </div>
        <div className="mb-4">
          <p className="font18">Over the years, we have built a solid reputation for excellence and reliability, earning the trust of a diverse clientele ranging from small businesses to large corporations across industries such as manufacturing, energy, Institutions, telecommunications, and more.</p>
        </div>
      </div>
      </div>
    </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const Heading = styled.h1`
font-size: 1.875rem;
font-weight: 700;
margin: 0px 0px 10px 0px;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;









