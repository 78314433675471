import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter } from 'react-router-dom';
import Footer from "./components/Sections/Footer";
import TopMenu from "./TopMenu.js";
import AppRoute from "./route.js";
import LeftNavigation from './screens/LeftNavigation.jsx';
import { Row, Col } from "antd";
import { useHistory } from 'react-router-dom';
import NoAccess from '../src/components/NoAccess.jsx'


export default function App() {

  const history = useHistory();    
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('TOKEN') ? true : false)
  const [pathName, setPathName] = useState(window.location.pathname)
  const adminRoute = ['/addpolicy ','/listproject2','/service2','/safety2','users','/addService','/addSafety','/addprojects2','/viewProject2','/userList','/addUser','/viewService2','/addclient']
  const [access,setAccess] = useState(true)
    useEffect(() => {
      if (adminRoute.includes(pathName) && loggedIn) {
        setLoggedIn(true);
      }
      else if (adminRoute.includes(pathName) && !loggedIn) {
        setLoggedIn(false);  
        setAccess(false)
      } 
      else {
        setAccess(true)
        setLoggedIn(false);

        }
    }, [pathName, history]);

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Inter&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,900;1,400;1,500;1,700;1,900&family=Teko:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
      </Helmet>


      <BrowserRouter>
        {pathName !== '/login' ? (
          !loggedIn ? (
            <>
              <div>
                <TopMenu />
                <Row style={{minHeight:300}}>
                  <Col span={24}>
                  {access?(<AppRoute />):
                  (<NoAccess />)}
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div>
              <Row >
                <Col span={5}>
                  <div className='col-md-auto sidebar-menu'>
                    <LeftNavigation />
                  </div>
                </Col>
                <Col span={19} style={{padding:40}}>
                  <AppRoute />
                </Col>
              </Row>
            </div>
            
            )
        ) : (
          <AppRoute />
        )}
      </BrowserRouter>
      {pathName !== '/login' && !loggedIn ? (<Footer />) : <></>}

    </>
  );
}

