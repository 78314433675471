import { Button, Divider, Col, ConfigProvider, List, Form, Input, Row, message } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import ProjectService from '../../services/ProjectService';
import DocumentService from '../../services/DocumentService'
import { useHistory, useLocation } from 'react-router-dom';
import Icon, { CloseOutlined } from '@ant-design/icons';
import TextEditor from '../TextArea/TextArea'


export default function AddProject() {
    const navigate = useHistory()
    const location = useLocation()
    const Params = new URLSearchParams(location.search);
    const [subProjectsForm] = Form.useForm();
    const [imageSrc, setImageSrc] = useState([])
    const [fileName, setFileName] = useState(null)
    const [imageId, setImageId] = useState([])
    const [descData, setDescData] = useState(null)
    const [file, setFile] = useState({
        name: null
    });
    const [formValues, setFormValues] = useState({
        subject: null,
        description: null
    });
    const fileInputRef = useRef(null);
    const triggerFileInput = () => {
        fileInputRef.current.click();
    }
    function removeImage(index){
        const newImageIds = [...imageId];
        newImageIds.splice(index, 1);
        setImageId(newImageIds);
        
        const newImageSrc = [...imageSrc];
        newImageSrc.splice(index, 1);
        setImageSrc(newImageSrc);
    }
    useEffect(() => {
        getProjectDataWithUrl();
    }, [Params.get('id')]);
    const getProjectDataWithUrl = () => {
        if(Params.get('id')){
        ProjectService.getOneSubProject({ id: Params.get('id') })
            .then((res) => {
                subProjectsForm.setFieldsValue(res && res.length > 0 ? res[0] : {})
                setImageId(res && res.length > 0 ? res[0].attachments : [])
                setImageSrc(res && res.length > 0 ? res[0].urlData : [])
                // setSubProjectData(res && res.length > 0 ? res[0] : {});
            })
            .catch((e) => {
                console.log(e);
            });
        }

    };
    const save = (value) => {
        value.attachments = JSON.stringify(imageId)
        value.project_id = Params.get('mainId')
        if (Params.get('id')) {
            value.id = Params.get('id')
        }
        ProjectService.createSubProject(value).then(res => {
            subProjectsForm.resetFields()
            navigate.push(`/viewProject2?id=${Params.get('mainId')}`)
            message.success("Save Successfully")
        }).catch(e => {
            message.error("Something Went Wrong")
            console.log(e)
        })


    }
    const handleFileUpload = async (event) => {
        const selectedFile = event.target.files[0];

        console.log(selectedFile, "uuuuee");

        if (selectedFile !== undefined) {
            const $file = selectedFile;
            setFile({ name: selectedFile.name });

            const fr = new FileReader();
            fr.readAsDataURL(selectedFile);

            fr.addEventListener('progress', (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    let progress = (progressEvent.loaded / progressEvent.total) * 100;
                    console.log(progressEvent.lengthComputable, "11111", progress);
                }
            });

            fr.addEventListener('load', async (loadEvent) => {
                if (loadEvent.lengthComputable) {
                    let progress1 = (loadEvent.loaded / loadEvent.total) * 100;
                    console.log(progress1, "00000");
                }

                const dataUrl = loadEvent.target.result;
                $file.url = dataUrl;
                $file.imagefile = selectedFile;

                const $json = {
                    doc_name: $file.name,
                    file_name: $file.name,
                    content_type: $file.imagefile.type,
                    active: 1,
                    data: $file.url
                };

                try {
                    const res = await DocumentService.upload($json);
                    const imageData = await DocumentService.imageProfile({ id: parseInt(res.id) });

                    setImageSrc(prevImageSrc => [...prevImageSrc, imageData]);
                    setImageId(prevImageId => [...prevImageId, res.id]);
                } catch (error) {
                    console.error(error);
                }
            });
        }
    };

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#364ea2',
                    },
                    components: {
                        Tabs: {
                            itemActiveColor: '#1677ff',
                        },
                        Progress: {
                            circleTextFontSize: '0.666666666666667em',
                        },
                        Tag: {
                            defaultColor: ''
                        }
                    }
                }
                }
            >
                <Row>
                    <Col span={24}>
                        <h3>Add Sub Project</h3>
                        <CloseOutlined onClick={() => { navigate.push(`/viewProject2?id=${Params.get('mainId')}`) }} style={{ float: 'right', cursor: 'pointer' }} />

                    </Col>
                </Row>
                <Divider plain></Divider>
                <Form
                    name="subProjectsForm"
                    onFinish={save}
                    autoComplete="off"
                    form={subProjectsForm}
                    layout="vertical"
                >

                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                            <Form.Item
                                name="subject"
                                label={<div className='Label'>Name</div>}
                                rules={[{ required: true, message: 'Project Name Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                            <Form.Item
                                name="location"
                                label={<div className='Label'>Location</div>}

                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="description"
                                label={<div className='Label'>Description</div>}
                                rules={[{ required: true, message: 'Description Is Required' }]}
                            >
                                <TextEditor form={subProjectsForm} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                            <div className='col-12'>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    accept=".jpg,.jpeg,.png"
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                />
                            </div>
                            <div className='col-12'>
                                <div className='uplodFile' onClick={triggerFileInput}>
                                    <div className='upldImg'></div>
                                    <div style={{ color: '#f38a50', fontSize: '15px', fontWeight: 500 }}>Upload Your File </div>
                                </div>
                                {/* {imageSrc && imageSrc.map((key, index) => (
                                    <img key={index} style={{ width: '15%', marginTop: 12 }} src={key} alt="John" />
                                ))} */}


                            </div>

                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} style={{ marginTop: 5 }}>
                        {imageSrc && imageSrc.length > 0 ? (
                            imageSrc.map((key, index) => (
                                <Col span={6} style={{ height: 250 }}>
                                    <img src={key} alt="" style={{ width: '100%', height: '100%' }} />
                                    <CloseOutlined
                                        onClick={()=>removeImage(index)}
                                        style={{
                                        position: 'absolute',
                                        top: -24,
                                        right: -10,
                                        fontSize: '16px',
                                        cursor: 'pointer',
                                        background: 'white',
                                        borderRadius: '50%',
                                        padding: '2px'
                                        }}
                                    />
                                </Col>
                            ))
                        ) : <></>}

                    </Row>
                    <Row style={{ marginRight: 50 }}>

                        <Col span={24} align="end" style={{ marginTop: 10 }}>
                            <Form.Item wrapperCol={{ offset: 1 }}>
                                <Button style={{ marginRight: '5px' }} onClick={() => { navigate.push(`/viewProject2?id=${Params.get('mainId')}`) }} htmlType="button">
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </ConfigProvider>
        </>
    )
}