import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import imhg from '../../../src/assets/img/upload_4640794.png'
import ProjectService from '../../services/ProjectService';
import DocumentService from '../../services/DocumentService'
import { useHistory } from 'react-router-dom';
import LeftNavigation from '../../screens/LeftNavigation';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useLocation } from 'react-router-dom';
import Editor from '../TextArea/TextArea'


export default function AddProject() {
  const location = useLocation();

  const history = useHistory();
  const projectId = new URLSearchParams(location.search).get('id');
  var actionType = new URLSearchParams(location.search).get('type');
  actionType = actionType == 'edit' ? "Edit" : 'Add'
  const [formValues, setFormValues] = useState({
    subject: null,
    description: null
  });
  const [imageSrc, setImageSrc] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [file, setFile] = useState({
    name: null
  });
  const fileInputRef = useRef(null);
  const triggerFileInput = () => {
    fileInputRef.current.click();
  }

  useEffect(() => {
    if (projectId) {
      getProjectData(projectId);
    }
  }, [projectId]);
  const getProjectData = (id) => {
    ProjectService.findOne({ id: id })
      .then((res) => {
        setFormValues(res);
        console.log(formValues, "iiiiiiiiiieeeyy")
        if (res && res.attachments) {
          getDocument(res.attachments);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getDocument = async (id) => {
    try {
      let $doc = await DocumentService.get({ id: parseInt(id) });
      console.log($doc.data.data[0].documents.file_name, "fffffffeeee", $doc && $doc.data && $doc.data.data && $doc.data.data.length > 0)
      setFileName($doc && $doc.data && $doc.data.data && $doc.data.data.length > 0 ? $doc.data.data[0].documents.file_name : '');
      console.log(fileName, $doc && $doc.data && $doc.data.data && $doc.data.data.length > 0 ? $doc.data.data[0].documents.file_name : '')
      let $image = await DocumentService.imageProfile({ id: parseInt(id) });
      setImageSrc($image);
    } catch (error) {
      console.error(error);
    }
  };


  const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0];

    console.log(selectedFile, "uuuuee");

    if (selectedFile !== undefined) {
      const $file = selectedFile;
      setFile({ name: selectedFile.name });

      const fr = new FileReader();
      fr.readAsDataURL(selectedFile);

      fr.addEventListener('progress', (progressEvent) => {
        if (progressEvent.lengthComputable) {
          let progress = (progressEvent.loaded / progressEvent.total) * 100;
          console.log(progressEvent.lengthComputable, "11111", progress);
        }
      });

      fr.addEventListener('load', async (loadEvent) => {
        if (loadEvent.lengthComputable) {
          let progress1 = (loadEvent.loaded / loadEvent.total) * 100;
          console.log(progress1, "00000");
        }

        const dataUrl = loadEvent.target.result;
        $file.url = dataUrl;
        $file.imagefile = selectedFile;

        const $json = {
          doc_name: $file.name,
          file_name: $file.name,
          content_type: $file.imagefile.type,
          active: 1,
          data: $file.url
        };

        try {
          const res = await DocumentService.upload($json);
          const imageData = await DocumentService.imageProfile({ id: parseInt(res.id) });
          console.log(imageData, "uuuuuuuuuuuu")
          setImageSrc(imageData);
          setFormValues((prevFormValues) => ({ ...prevFormValues, attachments: res.id }));
          setFileName(res.file_name);
        } catch (error) {
          console.error(error);
        }
      });
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "hhheeeeeewww")
    setFormValues((prevFormValues) => ({ ...prevFormValues, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Values:', formValues);
    try {
      if(!formValues.stage) {
        formValues.stage = 'Open'
      }
      await ProjectService.create(formValues);
      history.push('/listproject2');
    } catch (error) {
      console.error(error);
    }


  };


  return (
    <>
      <div className='row'>

        <div className='col-md admin-rhs'>
          <div className='row'>
            <div className='col-6 mx-auto'>
              <Form onSubmit={handleSubmit}>

                <div className='col-12'>
                  <h2>{actionType} Project</h2>
                </div>

                <div className='col-12' style={{ marginTop: '20px' }}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      size="lg"
                      name="subject"
                      value={formValues.subject}
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Subject"
                    />
                  </Form.Group>
                </div>
                {actionType == 'Edit' ?
                  <div className='col-12' style={{ marginTop: '20px' }}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Stage</Form.Label>
                      <Typeahead
                        id="autocomplete"
                        name="stage"
                        selected={formValues.stage ? [formValues.stage] : []}
                        onChange={(selected) => handleInputChange({ target: { name: 'stage', value: selected[0] || '' } })}
                        options={["Open", "Completed"]}
                        placeholder="Stage"
                      />
                    </Form.Group>
                  </div>
                  : <></>}
                <div className='col-12'>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      name="description"
                      value={formValues.description}
                      onChange={handleInputChange}
                      as="textarea"
                      rows={3}
                    />
                  </Form.Group>
                </div>
                <div className='col-12'>
                  <Editor />
                </div>
                <div className='col-12'>
                  <input
                    type="file"
                    ref={fileInputRef}
                    accept=".jpg,.jpeg,.png"
                    style={{ display: 'none' }}
                    onChange={handleFileUpload}
                  />
                </div>
                <div className='col-12'>
                  <div className='uplodFile' onClick={triggerFileInput}>
                    <div className='upldImg'></div>
                    <div style={{ color: '#f38a50', fontSize: '15px', fontWeight: 500 }}>Upload Your File</div>
                  </div>
                  {imageSrc && <img style={{ width: '15%', marginTop: 12 }} src={imageSrc} alt="John" />}
                </div>

                <div className='col-12'>
                  <Button className='mt-3 mr-3' onClick={() => history.push('/listproject')} variant="danger" type="reset">
                    Cancel
                  </Button>
                  <Button className='mt-3 lp-btn btn' type="submit">Save</Button>
                </div>


                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                  <div>
                    <div style={{ width: '100%' }}>

                      {' '}
                    </div>

                  </div>
                </div>
              </Form>
            </div>
          </div>

        </div>

      </div>
    </>
  );

}