import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row, Col,Card } from 'antd';
import developerImg from "../../assets/img/rightArrow.png";
import { useHistory } from 'react-router-dom';
import DocumentService from '../../services/DocumentService';
import ClientService from "../../services/ClientService";
import BackendService from "../../services/Service";

export default function Electrification() {
	const history = useHistory();
	const [serviceData, setServiceData] = useState([]);
	const [clientData, setClientData] = useState([]);

	useEffect(() => {
		getClientData();
		getService();
	}, []);

	const getClientData = async () => {
		try {
			const res = await ClientService.findAll();
			for (let i = 0; i < res.length; i++) {
				res[i].imageData = await getImageData(res[i].logo_id);
			}
			setClientData(res);
			console.log(res, "Client Data");
		} catch (e) {
			setClientData([]);
			console.log(e);
		}
	};

	const selectService = (item) => {
		history.push(`/viewService?id=${item.id}&type=view`);
	};

	const getService = async () => {
		try {
			const res = await BackendService.findAll();
			for (let i = 0; i < res.length; i++) {
				res[i].imageData = await getImageData(res[i].logo_id);
			}
			setServiceData(res);
		} catch (e) {
			setServiceData([]);
			console.log(e);
		}
	};

	const getImageData = async (id) => {
		if (!id) {
			return null;
		}
		const imageData = await DocumentService.imageProfile({ id: parseInt(id) });
		return imageData;
	};

	return (
		<Wrapper id="governance" className="mb-5">
			<Row className="SectionPage">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
					<ul style={{ listStyle: 'none' }}>
						<Row>
							{serviceData && serviceData.length > 0 && serviceData.map(item => (
								<Col key={item.id} xs={24} sm={12} md={10} lg={12} xl={12}>
									<List onClick={() => selectService(item)} className="mb-3 activeLink">
										<img className="linkList" src={item.imageData ? item.imageData : developerImg} alt="Avatar" style={{ width: '13px', height: '13px' }} />
										<a className="listName">{item.subject}</a>
									</List>
								</Col>
							))}
						</Row>
					</ul>
				</Col>
			</Row>
			<Row>
				<Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginTop: '40px' }}>
					<div className="container">
						<h1 className="font30 extraBold text-uppercase text-center" style={{ padding: "35px 0" }}>We have enabled and worked with</h1>
						<Row justify={'center'}>
							{clientData && clientData.length > 0 ? clientData.map((item, index) => (
								<Card style={{ backgroundColor: '#f0f0f0', width: '20%', height: '120px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',margin:'15px' }}>
                  <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <img className="linkList" src={item.imageData ? item.imageData : ''} alt="Avatar" style={{ width: '20%', height: '20%' }} /> 
                  </Row>
                  <span style={{ textAlign: 'center', marginTop: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {item.name}
                  </span>
                </Card>

							)) : <></>}
						</Row>
					</div>
				</Col>
			</Row>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	width: 100%;
`;

const List = styled.li`
	color: #DB373E;
	padding: 4px 0;
	font-weight: bold;
`;
