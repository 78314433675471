import React, { useEffect, useState } from "react";
import LandingPage from "./screens/Landing.jsx";
import Aboutus from "./components/Pages/Aboutus.jsx";
import Projects from "./components/Pages/Projects.jsx"
import AddProject from "./components/Sections/AddProject.jsx";
import AddProject2 from "./components/Project2/AddProject.jsx";
import AddSubProject from "./components/Project2/AddSubProject.jsx";
import ViewProject2 from "./components/Project2/ViewProject.jsx";
import ListProject from "./components/Sections/ListProject.jsx";
import ViewProject from "./components/Sections/ViewProject.jsx";
import Contactus from "./components/Pages/Contactus.jsx";
import Certifications from "./components/Pages/Certifications.jsx";
import ProjectView from "./components/Pages/ProjectView.jsx";
import ProjectOverView from "./components/Pages/ProjectOverView.jsx";
import Service from "./components/Pages/Service/Service.jsx";
import ServiceView from "./components/Pages/Service/ServiceView.jsx";
import Safety from "./components/Pages/safety/Safety.jsx";
import { BrowserRouter as Router, Route, NavLink, Switch,useHistory,useLocation } from 'react-router-dom';
import Login from "./components/Login/Login.jsx";
import Table from './Table.js'
import ProjectList from './components/Project2/Project.jsx'
import CommitmentSafety from "./components/Pages/safety/CommitmentSafety.jsx";
import SafetyTrainnig from "./components/Pages/safety/SafetyTrainnig.jsx";
import Standart from "./components/Pages/Standard.jsx";
import User from "./components/User/User.jsx"
import Service2 from "./components/Service/Service.jsx";
import AddService from "./components/Service/AddService.jsx";
import ViewService from "./components/Service/ViewService.jsx";
import Safety2 from "./components/Safety/Safety.jsx";
import AddSafety from "./components/Safety/AddSafety.jsx";
import AddClient from "./components/client/AddClient.jsx";
import ViewSafety from "./components/Safety/ViewSafety.jsx";
import AddUser from "./components/User/AddUser.jsx";
import NoAccess from '../src/components/NoAccess.jsx';
import AddPolicy from './components/Safety/AddPolicy.jsx';
export default function AppRoute() {
    // const history = useHistory();
    //   const checkRoute = () => {
    //     alert(localStorage.getItem('lastPath'))
    //     if (history.location.pathname) {
    //         alert('You are accessing the Projects page!');
    //         localStorage.setItem('lastPath',history.location.pathname)
    //     }
    //   };

    //   useEffect(() => {
    //     alert('hi')
    //     return history.listen(checkRoute);
    //   }, [history]);
    return(
        <>
      <Switch>
        <Route exact path="/aboutus" component={Aboutus} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/contactus" component={Contactus} />
        <Route exact path="/certifications" component={Certifications} />
        <Route exact path="/projectview" component={ProjectView} />
        <Route exact path="/projectoverview" component={ProjectOverView} />
        <Route exact path="/service" component={Service} />
        <Route exact path="/serviceView" component={ServiceView} />
        <Route exact path="/safety" component={Safety} />
        <Route exact path="/commitmentsafety" component={CommitmentSafety} />
        <Route exact path="/safetytrainnig" component={SafetyTrainnig} />
        <Route exact path="/standard" component={Standart} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/addprojects" component={AddProject} />
        <Route exact path="/addprojects2" component={AddProject2} />
        <Route exact path="/addSubProject" component={AddSubProject} />
        <Route exact path="/listproject" component={ListProject} />
        <Route exact path="/service2" component={Service2} />
        <Route exact path="/addService" component={AddService} />
        <Route exact path="/viewService" component={ViewService} />
        <Route exact path="/listproject2" component={ProjectList} />
        <Route exact path="/safety2" component={Safety2} />
        <Route exact path="/addSafety" component={AddSafety} />
        <Route exact path="/addclient" component={AddClient} />
        <Route exact path="/viewSafety" component={ViewSafety} />
        <Route exact path="/listproject2" component={ProjectList} />
        <Route exact path="/viewproject" component={ViewProject} />
        <Route exact path="/viewproject2" component={ViewProject2} />
        <Route exact path="/userList" component={User} />
        <Route exact path="/addUser" component={AddUser} />
        <Route exact path="/noAccess" component={NoAccess} />
        <Route exact path="/table" component={Table} />
        <Route exact path="/addpolicy" component={AddPolicy} />
        <Route exact  path="/" render={(props) => (
              <LandingPage />
            )}
          />
      </Switch>
      </>
    )
}