import React from "react";
import { useEffect, useState } from 'react';
import { Button, Flex,Carousel } from 'antd';
import styled from "styled-components";
import { Col, Row, } from 'antd';
// Components
import rhsimg from "../../assets/img/banner3.jpg";
import { useHistory } from 'react-router-dom';
import secondImage from '../../assets/img/banner2.jpg'
import thirdImage from '../../assets/img/slider-5.jpg'
import ProjectService from '../../services/ProjectService';

export default function Projects() {
  const history = useHistory();
  const [cardData, setCardData] = useState([])

  useEffect(() => {
    getProjectDataWithUrl();
  }, []);
  const getProjectDataWithUrl = () => {
    ProjectService.findAllWithUrl()
      .then((res) => {
        setCardData(res && res.length > 0 ? res : [])
      })
      .catch((e) => {
        setCardData([])
        console.log(e);
      });
  };

  const viewProject = (value) => {
    history.push(`/projectview?id=${value.id}`)
  }
  const [AllappsData, setAllappsData] = useState([])
  const data = [
    {img:thirdImage},
    {img:thirdImage},
    {img:thirdImage},
  ]
  // [
  //   { 
  //     id:1,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p1
  //   },
  //   {
  //     id:2,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p2
  //   },
  //   {
  //     id:3,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p1
  //   },
  //   {
  //     id:4,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p2
  //   },
  //   {
  //     id:5,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p1
  //   },
  //   {
  //     id:6,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p2
  //   },
  //   {
  //     id:7,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p1
  //   },
  //   {
  //     id:8,
  //     name:'ELECTRICAL WORKS TENDER BASED ON UNIT RATE/SUWAIQ',
  //     description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
  //     url:'app.lyra.finance',
  //     iconImage:p2
  //   },
  // ]
  // useEffect(() => {
  //   if (document.body.classList.contains('all-apps')) {
  //     console.log('body tag contains class');
  //   }
  //   else {
  //     document.body.classList.remove('all-apps');
  //   }
  // }, []);

  return (
    <Wrapper id="projects">
      <div className="">
      <Carousel autoplay>
      {data && data.length > 0 && data.map(item => ( <div>
      <div style={{backgroundImage:"url("+`${item.img}`+")",height:450, backgroundSize: 'cover',backgroundRepeat: 'no-repeat', }}>
      <div style={{width:'100%',height:'100%',background:'black',opacity:0.5,display:'flex',justifyContent:'center',alignItems:'center',color:'#fff'}}>
                <h1 className="corousal-header"><strong>Our Projects</strong></h1>
                </div>
                {/* <div className="col-md-6 textRight">
                <img src={bannerImg} className="img-fluid allapps-banner"/>
              </div> */}
            </div>
            </div>))}
  </Carousel>
        <div className="container content-wrapper" style={{ margin: "50px auto 0 auto" }}>
          <h3 className="font16 my-2"><strong>Your reputation is our reputation.</strong></h3>
          <p style={{ fontSize: 16,marginBottom:20 }}>Over six decades of continuous operation, Al Gulnar Al Fawah Trad and Cont has applied its engineering and construction expertise to virtually every type of electrical system, from 765kV transmission lines and substations to inside electrical systems and low voltage wiring. Al Gulnar Al Fawah Trad and Cont is unique in offering this breadth of expertise which encompasses all electrical applications from power generation to consumption.</p>
          {/* <Row>
            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <div  className="row projetCard textCenter mb-5">
            <div  onClick={viewProject} class="flip-card">
              <div class="flip-card-inner">
                <div style={{backgroundImage:"url("+`${thirdImage}`+")", backgroundSize: 'cover',backgroundRepeat: 'no-repeat', }} class="flip-card-front">
                  <div style={{display:'flex',alignItems:'end',height:'100%',justifyContent:'center'}}>
                  <h5 style={{background:'black',opacity:0.5,width:'100%',padding:15}}>Power Generation</h5>
                  </div>
                </div>
                <div class="flip-card-back">
                <Button className="projectViewbutton">View More</Button>
                </div>
              </div>
            </div>
            {/* {AllappsData.map((app, index) => (
            <div style={{padding:10}} className="col-xs-12 col-sm-4 col-md-4 col-lg-3" key={index}>
             <Bounce>
             
              <Wrapper className="radius8 shadow all-apps-card h-100"> */}
            {/* <div className="flex flexNullCenter flex-end">
                  <div><img src={liveIcon} alt="office" className="img-fluid" /></div>
                  <div className="font16 primaryColor">Live Projects</div>
                </div> */}
            {/* <div className="h-100">
                  <div className="project-header">
                  {app.url ? <img src={app.url} alt="" className="img-fluid appsImage" /> : <></>}
                  </div>
                  <div className="p-4">
                  <div className="font15 extraBold mb-0">Title</div>
                   <div className="font14 mb-0 bridge-title">{app.subject}</div>
                  </div>                 
                  {/* <div className="">{app.url}</div> 
                </div> */}
            {/* <Fade bottom>
                  <p className="bridge-txt" style={{ padding: "30px 0" }}>
                    {app.description}
                  </p>
                </Fade> */}
            {/* </Wrapper>
              </Bounce>
            </div>
          ))} 
          </div>
          </Col>
          </Row> */}
          <Row gutter={40}>
        {cardData && cardData.length > 0 && cardData.map(item => <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <div className="row projetCard textCenter mb-5">
            <div onClick={() => viewProject(item)} class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img style={{ width: '100%', height: 260 }} src={item.url} alt="Avatar" />
                  <h5>{item?.subject || ''}</h5>
                </div>
                <div class="flip-card-back">
                  <Button className="projectViewbutton" onClick={() => viewProject(item)}>View More</Button>
                </div>
              </div>
            </div>
          </div>
        </Col>
        )}
      </Row>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
width: 100%;
text-align: left;
`;
const HeaderInfo = styled.div`
  color:#000;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
