import { Button, Divider, Select, Col, ConfigProvider, List, Form, Input, Row, message } from 'antd';

import React, { useState, useEffect, useRef } from 'react';
import PolicyService from '../../services/PolicyService';
import DocumentService from '../../services/DocumentService'
import { useHistory, useLocation } from 'react-router-dom';
import Icon, { CloseOutlined } from '@ant-design/icons';
import TextEditor from '../TextArea/TextArea'

const { Option } = Select; // Add this line to import Option

export default function AddSafety() {
    const navigate = useHistory()
    const options = [
        { value: 'Open', label: 'Open' },
        { value: 'Completed', label: 'Completed' }
    ];
    const [SafetyForm] = Form.useForm();
    const [imageSrc, setImageSrc] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [imageId, setImageId] = useState(null)
    const [descData, setDescData] = useState(null)
    const location = useLocation()
    const Params = new URLSearchParams(location.search);
    const [file, setFile] = useState({
        name: null
    });
    const [formValues, setFormValues] = useState({
        subject: null,
        description: null
    });
    const fileInputRef = useRef(null);
    const triggerFileInput = () => {
        fileInputRef.current.click();
    }
    const save = (value) => {
        if (Params.get('id')) {
            value.id = Params.get('id')
        }
        PolicyService.create(value).then(res => {
            if (res && res.data && res.data.data) {
                navigate.push(`/viewSafety?id=${res.data.data.id}`)
                message.success("Save Successfully")
            }
            else {
                navigate.push(`/viewSafety2?id=${Params.get('id')}`)
                message.success("Update Successfully")

            }
        }).catch(e => {
            message.error("Something Went Wrong")
            console.log(e)
        })


    }
    useEffect(() => {
        if (Params.get('id')) {
            getById(Params.get('id'))
        }

    }, [Params.get('type'), Params.get('id')])

    const getById = (id) => {
        PolicyService.findOne({ id: id }).then(async res => {
            SafetyForm.setFieldsValue(res)
            const imageData = await DocumentService.imageProfile({ id: parseInt(res.attachments) });
            setImageId(res.attachments)
            setImageSrc(imageData);

        }).catch(e => {
            console.log(e)
        })
    }

    const handleFileUpload = async (event) => {
        const selectedFile = event.target.files[0];

        console.log(selectedFile, "uuuuee");

        if (selectedFile !== undefined) {
            const $file = selectedFile;
            setFile({ name: selectedFile.name });

            const fr = new FileReader();
            fr.readAsDataURL(selectedFile);

            fr.addEventListener('progress', (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    let progress = (progressEvent.loaded / progressEvent.total) * 100;
                    console.log(progressEvent.lengthComputable, "11111", progress);
                }
            });

            fr.addEventListener('load', async (loadEvent) => {
                if (loadEvent.lengthComputable) {
                    let progress1 = (loadEvent.loaded / loadEvent.total) * 100;
                    console.log(progress1, "00000");
                }

                const dataUrl = loadEvent.target.result;
                $file.url = dataUrl;
                $file.imagefile = selectedFile;

                const $json = {
                    doc_name: $file.name,
                    file_name: $file.name,
                    content_type: $file.imagefile.type,
                    active: 1,
                    data: $file.url
                };

                try {
                    const res = await DocumentService.upload($json);
                    const imageData = await DocumentService.imageProfile({ id: parseInt(res.id) });
                    setImageSrc(imageData);
                    setFormValues((prevFormValues) => ({ ...prevFormValues, attachments: res.id }));
                    setImageId(res.id)
                    setFileName(res.file_name);
                } catch (error) {
                    console.error(error);
                }
            });
        }
    };

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#364ea2',
                    },
                    components: {
                        Tabs: {
                            itemActiveColor: '#1677ff',
                        },
                        Progress: {
                            circleTextFontSize: '0.666666666666667em',
                        },
                        Tag: {
                            defaultColor: ''
                        }
                    }
                }
                }
            >
                <Row>
                    <Col span={24}>
                        <h3>Add Policy</h3>
                        <CloseOutlined onClick={() => { navigate.push('/service2') }} style={{ float: 'right', cursor: 'pointer' }} />

                    </Col>
                </Row>
                <Divider plain></Divider>
                <Form
                    name="serviceForm"
                    onFinish={save}
                    autoComplete="off"
                    form={SafetyForm}
                    layout="vertical"
                >

                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                            <Form.Item
                                name="name"
                                label={<div className='Label'>Name</div>}
                                rules={[{ required: true, message: 'Safety Name Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                            <Form.Item
                                name="description"
                                label={<div className='Label'>Description</div>}
                                rules={[{ required: true, message: 'Description Name Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                            <Form.Item
                                name="link_name"
                                label={<div className='Label'>Link Name</div>}
                                rules={[{ required: true, message: 'Link Name Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                            <Form.Item
                                name="link"
                                label={<div className='Label'>Link</div>}
                                rules={[{ required: true, message: 'Link Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 50 }}>

                        <Col span={24} align="end" style={{ marginTop: 10 }}>
                            <Form.Item wrapperCol={{ offset: 1 }}>
                                <Button style={{ marginRight: '5px' }} onClick={() => { navigate.push(`/service2`) }} htmlType="button">
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </ConfigProvider>
        </>
    )
}