import { Row, Col, Carousel } from 'antd';
import thirdImage from '../../../assets/img/slider-3.jpg'
import styled from "styled-components";
import developerImg from "../../../assets/img/rightArrow.png";
import { useHistory } from 'react-router-dom';
export default function CommitmentSafety() {
    const history = useHistory();
    const FirstList = [
        { label: 'Incidents can be prevented through implementation of effective safety and health policies and procedures.',},
        { label: 'Safety controls are a vital part of our daily work practices, and integral to our work planning.'},
        { label: 'Adherence to all safety processes is a condition of employment.'},
        { label: 'Unsafe acts are unacceptable.'},
        { label: 'Incidents are reported promptly, analyzed promptly and corrective action is taken.'},
        { label: 'Any employee has the right, and the responsibility, to stop the work if they feel safety is being compromised.'},
        { label: 'This preventative approach requires participation and ownership from all levels of the company:',},
        { label: 'Incident prevention is not just good for our employees, it is good for our business as it increases productivity and minimizes injury.'},
    ]
    const selectService = (code) =>{
        history.push(`/serviceView?code=${code}`)
    }
    return (
        <>
            <div className="container  my-3" >
                <h1 className='mb-5'><strong>Commitment to Safety</strong></h1>
                <h4>Safety Commitment Statement</h4>
                <p style={{ fontSize: 20, marginTop: 15 }}>Al Gulnar Al Fawah Trad and Cont’s SAFE program is focused on helping all Al Gulnar Al Fawah Trad and Cont employees Stay Accident Free Every Day. We implemented this program to provide a focal point for our commitment to safety. The SAFE Program is based on five core tenets: Planning, Training, Awareness, Communication and Accountability. It is through these five strategic pillars that we develop and implement safety and health policies and procedures that encourage our employees to take personal responsibility for the safety of themselves and their work colleagues.</p>
                <p style={{ fontSize: 20, marginTop: 15 }}>Each person who works at Al Gulnar Al Fawah Trad and Cont is important. Our success with customers, and consequently the overall success of this business, depends upon the individuals working for this company -- his or her personal skills, energies and contributions. Because we are united in achieving success, we are concerned for and supportive of each other’s safety and health.</p>
                <p style={{ fontSize: 20, marginTop: 15 }}>Al Gulnar Al Fawah Trad and Cont believes strongly in the prevention of accidents before they happen. This principle is supported by the following basic concepts:</p>
                <Row style={{ padding: 25, paddingLeft: 50 }}>
                {FirstList.map((item,index) => <Col key={index} xs={24} sm={24} md={24} lg={24} xl={24}>
                        <ul>
                            <List>
                                <a  >{item.label}</a>
                            </List>
                        </ul>
                      {index === 6 && <UList>
                        <List1> <a  >Management, project superintendents, and foremen are responsible for executing the company’s safety program within their areas of supervision, assuring proper and positive corrective actions, and communicating with upper management, the safety department, and the work force.</a></List1>
                        <List1> <a  >Employee involvement means the employee’s responsibility to him/herself for his/her own safety, including maintaining open communications on safety issues, reporting unsafe conditions, observing company safety and health rules, using common sense, and utilizing safe work habits.</a></List1>
                       </UList>}
                    </Col>)}
                </Row>
                <p style={{ fontSize: 20, marginTop: 15,marginBottom:60 }}>Keeping a safe workplace is a team effort and your involvement, cooperation, and personal commitment to safety are essential. Together, we can make the difference in the safety of ourselves and our fellow employees. Through our personal involvement and commitment, we can prevent injuries and ensure that we all stay accident free every day.</p>
            </div>
        </>
    )
}
const List = styled.li`
    color:#000000;
    padding: 4px 0;
    font-size:20px
`;
const List1 = styled.li`
    color:#000000;
    padding: 4px 0;
    font-size:17px
`;
const UList = styled.ul`
list-style: circle;
margin-left: 50px;
`;