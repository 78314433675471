import { Button, Divider, Select, Col, ConfigProvider, List, Form, Input, Row, message } from 'antd';

import React, { useState, useEffect, useRef } from 'react';
import Service from '../../services/Service';
import DocumentService from '../../services/DocumentService'
import { useHistory, useLocation } from 'react-router-dom';
import Icon, { CloseOutlined } from '@ant-design/icons';
import TextEditor from '../TextArea/TextArea'

const { Option } = Select; // Add this line to import Option

export default function AddService() {
    const navigate = useHistory()
    const options = [
        { value: 'Open', label: 'Open' },
        { value: 'Completed', label: 'Completed' }
    ];
    const [ServiceForm] = Form.useForm();
    const [imageSrc, setImageSrc] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [imageId, setImageId] = useState(null)


    const [logoSrc, setLogoSrc] = useState(null)
    const [logoName, setLogoName] = useState(null)
    const [logoId, setLogoId] = useState(null)

    const [descData, setDescData] = useState(null)
    const location = useLocation()
    const Params = new URLSearchParams(location.search);
    const [file, setFile] = useState({
        name: null
    });
    const [formValues, setFormValues] = useState({
        subject: null,
        description: null
    });
    const fileInputRef = useRef(null);
    const triggerFileInput = () => {
        fileInputRef.current.click();
    }
    const fileInputRefLogo = useRef(null);
    const triggerFileInputLogo = () => {
        fileInputRefLogo.current.click();
    }
    const save = (value) => {
        value.attachments = imageId
        value.logo_id = logoId
        if (Params.get('id')) {
            value.id = Params.get('id')
        }
        Service.create(value).then(res => {
            if (res && res.data && res.data.data) {
                navigate.push(`/service2`)
                message.success("Save Successfully")
            }
            else {
                navigate.push(`/service2`)
                message.success("Update Successfully")

            }
        }).catch(e => {
            message.error("Something Went Wrong")
            console.log(e)
        })


    }
    useEffect(() => {
        if (Params.get('id')) {
            getById(Params.get('id'))
        }

    }, [Params.get('type'), Params.get('id')])

    const getById = (id) => {
        Service.findOne({ id: id }).then(async res => {
            ServiceForm.setFieldsValue(res)
            const imageData = await getImageData(res.attachments);
            setImageId(res.attachments)
            setImageSrc(imageData);
            
            const logoData = await getImageData(res.logo_id);
            setLogoId(res.logo_id)
            setLogoSrc(logoData);

        }).catch(e => {
            console.log(e)
        })
    }
    const getImageData = async (id) =>{
        const imageData = await DocumentService.imageProfile({ id: parseInt(id) });
        return imageData
    }
    const removeImage = () =>{
            setImageSrc(null);
            setImageId(null)
            setFileName(null);
    }
    
    const removeIcon = () =>{                               
        setLogoSrc(null);
        setLogoId(null)
        setLogoName(null);
    }
 
    const handleFileUpload = async (event,type) => {
        const selectedFile = event.target.files[0];

        console.log(selectedFile, "uuuuee");

        if (selectedFile !== undefined) {
            const $file = selectedFile;
            setFile({ name: selectedFile.name });

            const fr = new FileReader();
            fr.readAsDataURL(selectedFile);

            fr.addEventListener('progress', (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    let progress = (progressEvent.loaded / progressEvent.total) * 100;
                    console.log(progressEvent.lengthComputable, "11111", progress);
                }
            });

            fr.addEventListener('load', async (loadEvent) => {
                if (loadEvent.lengthComputable) {
                    let progress1 = (loadEvent.loaded / loadEvent.total) * 100;
                    console.log(progress1, "00000");
                }

                const dataUrl = loadEvent.target.result;
                $file.url = dataUrl;
                $file.imagefile = selectedFile;

                const $json = {
                    doc_name: $file.name,
                    file_name: $file.name,
                    content_type: $file.imagefile.type,
                    active: 1,
                    data: $file.url
                };

                try {
                    const res = await DocumentService.upload($json);
                    const imageData = await DocumentService.imageProfile({ id: parseInt(res.id) });
                    console.log(type,"uuuuuuuuuuuuussss")
                    if(type == 'img'){
                        setImageSrc(imageData);
                        setFormValues((prevFormValues) => ({ ...prevFormValues, attachments: res.id }));
                        setImageId(res.id)
                        setFileName(res.file_name);
                    }
                    else{                        
                        setLogoSrc(imageData);
                        setFormValues((prevFormValues) => ({ ...prevFormValues, logo_id: res.id }));
                        setLogoId(res.id)
                        setLogoName(res.file_name);
                    }
                } catch (error) {
                    console.error(error);
                }
            });
        }
    };

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#364ea2',
                    },
                    components: {
                        Tabs: {
                            itemActiveColor: '#1677ff',
                        },
                        Progress: {
                            circleTextFontSize: '0.666666666666667em',
                        },
                        Tag: {
                            defaultColor: ''
                        }
                    }
                }
                }
            >
                <Row>
                    <Col span={24}>
                        <h3>Add Service</h3>
                        <CloseOutlined onClick={() => { navigate.push('/service2') }} style={{ float: 'right', cursor: 'pointer' }} />

                    </Col>
                </Row>
                <Divider plain></Divider>
                <Form
                    name="serviceForm"
                    onFinish={save}
                    autoComplete="off"
                    form={ServiceForm}
                    layout="vertical"
                >

                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                            <Form.Item
                                name="subject"
                                label={<div className='Label'>Name</div>}
                                rules={[{ required: true, message: 'Service Name Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                            <Form.Item
                                name="stage"
                                label={<div className="Label">Stage</div>}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    size="large"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "").toLowerCase().includes(input)
                                    }
                                >
                                    {options.map(option => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="description"
                                label={<div className='Label'>Description</div>}
                                rules={[{ required: true, message: 'Description Is Required' }]}
                            >
                                <TextEditor form={ServiceForm} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{marginBottom:'15px'}}>
                    <span><strong>Upload your service logo</strong></span>
                    </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                            <div className='col-12'>
                                <input
                                    type="file"
                                    ref={fileInputRefLogo}
                                    accept=".jpg,.jpeg,.png"
                                    style={{ display: 'none' }}
                                    onChange={($event)=>handleFileUpload($event,'logo')}
                                />
                            </div>
                            <div className='col-12'>
                                <div className='uplodFile' onClick={triggerFileInputLogo}>
                                    <div className='upldImg'></div>
                                    <div style={{ color: '#f38a50', fontSize: '15px', fontWeight: 500 }}>Upload your logo</div>
                                </div>
                                {/* {imageSrc && <img style={{ width: '15%', marginTop: 12 }} src={imageSrc} alt="John" />} */}
                            </div>

                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
                        {logoSrc ? (
                            <Col span={6} style={{ height: 150 }}>
                                <img src={logoSrc} alt="" style={{ width: '50%', height: '50%' }} />
                                <CloseOutlined
                                        onClick={()=>removeIcon()}
                                        style={{
                                        position: 'absolute',
                                        top: -24,
                                        right: -10,
                                        fontSize: '16px',
                                        cursor: 'pointer',
                                        background: 'white',
                                        borderRadius: '50%',
                                        padding: '2px'
                                        }}
                                    />
                            </Col>

                        ) : <></>}

                    </Row>
                    
                    <Row style={{marginTop:'5px'}}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{marginBottom:'15px'}}>
                    <span><strong>Upload your service image</strong></span>
                    </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                            <div className='col-12'>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    accept=".jpg,.jpeg,.png"
                                    style={{ display: 'none' }}
                                    onChange={($event)=>handleFileUpload($event,'img')}
                                />
                            </div>
                            <div className='col-12'>
                                <div className='uplodFile' onClick={triggerFileInput}>
                                    <div className='upldImg'></div>
                                    <div style={{ color: '#f38a50', fontSize: '15px', fontWeight: 500 }}>Upload Your File</div>
                                </div>
                                {/* {imageSrc && <img style={{ width: '15%', marginTop: 12 }} src={imageSrc} alt="John" />} */}
                            </div>

                        </Col>
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginTop: 5 }}>
                        {imageSrc ? (
                            <Col span={6} style={{ height: 250 }}>
                                <img src={imageSrc} alt="" style={{ width: '100%', height: '100%' }} />
                                <CloseOutlined
                                        onClick={()=>removeImage()}
                                        style={{
                                        position: 'absolute',
                                        top: -24,
                                        right: -10,
                                        fontSize: '16px',
                                        cursor: 'pointer',
                                        background: 'white',
                                        borderRadius: '50%',
                                        padding: '2px'
                                        }}
                                    />
                            </Col>

                        ) : <></>}

                    </Row>
                    
                    
                    <Row style={{ marginRight: 50 }}>

                        <Col span={24} align="end" style={{ marginTop: 10 }}>
                            <Form.Item wrapperCol={{ offset: 1 }}>
                                <Button style={{ marginRight: '5px' }} onClick={() => { navigate.push(`/service2`) }} htmlType="button">
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </ConfigProvider>
        </>
    )
}