import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.jpg";
// import image from "image path";
import { useHistory } from 'react-router-dom';


const LeftNavigation = (props) => {
	const history = useHistory();
	const [isNotActive, setNotActive] = useState("true");
	const [menuData, setMenuData] = useState(
		[
			{
				name: "All Project",
				route: '/listproject2',
				active: true
			},
			{
				name: "OnGoing Project",
				route: '/listproject2?stage=Open',
				active: false
			},
			{
				name: "Completed Projects",
				route: '/listproject2?stage=Completed',
				active: false
			},
			{
				name: "Service",
				route: '/service2',
				active: false
			},
			{
				name: "Safety",
				route: '/safety2',
				active: false
			},
			{
				name: "User",
				route: '/userList',
				active: false
			},
				{
					name: "Logout",
					route: '/logout'
			},

		]
	)
	const [isDropdownActive, setDropdownActive] = useState("false");
	var barsIcon = <i className="fas fa-bars"></i>
	var crossIcon = <i className="fas fa-times-circle"></i>
	function getLogout() {
		localStorage.clear()
		history.push('/')
		window.location.reload()
	}
	async function goSelectedPage(item) {
		if (item.route == '/logout') {
			localStorage.clear()
			history.push('/')
			window.location.reload()
		}
		else {
			await setActive(item)
			history.push(item.route)
		}


	}
	async function setActive(item) {
		const updatedMenuData = menuData.map(k => {
			return {
				...k,
				active: k.name === item.name ? true : false
			};
		});

		setMenuData(updatedMenuData);
	}
	return (
		<div>
			<div className="wrapper">
				<nav id="sidebar" className={isNotActive ? "active" : ""}>
					{/* <button
						type="button"
						id="sidebarCollapse"
						onClick={() => setNotActive(!isNotActive)}
						className="btn btn-custom"
					>
						<span className={ isNotActive ? '' : 'hidden' }>{ barsIcon }</span>
						<span className={ isNotActive ? 'hidden' : '' }>{ crossIcon }</span>
					</button> */}
					<div className="sidebar-header">
						<img
							src={Logo}
							className="usr-image w-75"
						/>
					</div>

					<ul className="list-unstyled components">
						{menuData && menuData.map((item) => (
							<li className={`list-item ${item.active ? 'list-active' : ''}`} key={item.id} onClick={() => goSelectedPage(item)}>
								{/* <i className="fas fa-briefcase icon-color"></i> */}
								<Link>{item.name}</Link>
							</li>
						))}
					</ul>
				</nav>
			</div>
		</div>
	);
};
export default LeftNavigation;

const Wrapper = styled.section`
	width: 100%;
`;
const HeaderInfo = styled.div`
	@media (max-width: 860px) {
		text-align: center;
	}
`;
