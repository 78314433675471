import { Row, Col,Carousel } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import ProjectService from '../../services/ProjectService';
import { useEffect, useState } from 'react';
import DocumentService from '../../services/DocumentService'

export default function ProjectView() {
    const history = useHistory();
    const location = useLocation()
    const Params = new URLSearchParams(location.search);
    const [projactData, setProjectData] = useState({})
    const [subProjectData, setSubProjectData] = useState([])
    useEffect(() => {
        getProjectData()
    }, [Params.get('id')])
    const getProjectData = () => {
        ProjectService.getProjectList({ projectId: Params.get('id') }).then(async res => {
            console.log(res,'resuuuuuuuuuuuuu');
            setProjectData(res && res.length > 0 ? res[0] : {})
            let $subProjectData = res && res.length > 0 && res[0].sub_project_data ? JSON.parse(res[0].sub_project_data) : []
            for (let i = 0; i < $subProjectData.length; i++) {
                let attachement = $subProjectData[i] && $subProjectData[i].attachments ? JSON.parse($subProjectData[i].attachments) : []
                if (attachement && attachement.length > 0) {
                    $subProjectData[i].url = await getImageData(attachement[0])
                }

            }
            setSubProjectData($subProjectData)
        }).catch(e => {
            setProjectData({})
            console.log(e)
        })
    }
    async function getImageData(id) {
        let res = await DocumentService.imageProfile({ id: parseInt(id) });
        return res || null
    }
    const FirstList = [
        { label: 'Traditional Generation' },
        { label: 'Renewable Generation' },
        { label: 'Gas' },
        { label: 'Hydroelectric' },
        { label: 'Nuclear' },
        { label: 'Industrial Cogeneration' },
    ]
    const overViewProject = (value) => {
        history.push(`/projectoverview?id=${value.id}`)
    }
    return (
        <>
         <Carousel autoplay>
                    {projactData && projactData.urlData && projactData.urlData.length > 0 && projactData.urlData.map(item => ( <div>
                    <div style={{backgroundImage:`url(${item})`,height:450, backgroundSize: 'cover',backgroundRepeat: 'no-repeat', }}>
                    <div style={{width:'100%',height:'100%',background:'black',opacity:0.5,display:'flex',justifyContent:'center',alignItems:'center',color:'#fff'}}>
                                <h1 className="corousal-header"><strong>{projactData?.subject || ''}</strong></h1>
                                </div>
                            </div>
                            </div>))}
                </Carousel>
            <div className="container  my-5 content-wrapper" >
               
                <h2 className='mb-4'><strong>{projactData?.subject || ''}</strong></h2>
                <Row>
                    <Col className='viewProject' xs={24} sm={24} md={24} lg={24} xl={24} dangerouslySetInnerHTML={{ __html: projactData?.description || '' }} />
                </Row>
                <Row>
                {subProjectData && subProjectData.length ? (
                    subProjectData.map((key, index) => (
                        <Col xs={24} sm={24} md={12} lg={8} xl={8} key={index}>
                            <div className="box-wrapper">
                                <figure className="shape-box shape-box_half">
                                    <img src={key.url} alt="" />
                                    <div className="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
                                    <figcaption>
                                        <div className="show-cont">
                                            <h3 className="card-no">{index + 1} </h3>
                                            <h4 className="card-main-title">{projactData?.subject || ''}</h4>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: key?.location || '' }} />
                                        <a className="read-more-btn" onClick={() => overViewProject(key)}>Read More</a>
                                    </figcaption>
                                    <span className="after"></span>
                                </figure>
                            </div>
                        </Col>
                    ))
                ) : <></>}


            </Row>
            </div>
        </>
    )
}