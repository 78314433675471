import React, { useEffect, useState } from "react";
import Logo from "./assets/img/logo.jpg";
import { BrowserRouter as Router, Route, NavLink, Link, Switch } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import styled from "styled-components";
import { Col, Row, Button,Drawer } from 'antd';
import MobileMenu from "./components/mobileMenu/MobileMenu";
import { useHistory } from 'react-router-dom';
import { PhoneOutlined, MailOutlined, CaretDownOutlined } from '@ant-design/icons';
import ProjectService from "./services/ProjectService";
import Service from "./services/Service";
import SafetyService from "./services/SafetyService";

export default function TopMenu() {
  const history = useHistory();
  const [y, setY] = useState(window.scrollY);
  const [expanded, setExpanded] = useState(false);
  const [project, setProject] = useState({})
  const [serviceData, SetServiceData] = useState({})
  const [safetyData, SetSafetyData] = useState({})
  const [open, setOpen] = useState(false);
  let data =
  {
    name: 'Project', url: 'projects',
    listData: [
      { title: 'Power Generation' },
      { title: 'Renewable Energy' },
      { title: 'Substations' },
      { title: 'Transmission/ Distribution' },
    ]
  }
  const menuData = [
    // {
    //   name: 'SERVICES ', url: 'service',
    //   listData: [
    //     { title: 'Engineering', code: 'engin',url: 'serviceView' },
    //     { title: 'Construction', code: 'constract',url: 'serviceView' },
    //     { title: 'Testing', code: 'test',url: 'serviceView' },
    //     { title: 'Service & Maintenance', code: 'mainteance',url: 'serviceView' },
    //   ]
    // },
    // {
    //   name: 'SAFETY', url: 'safety',
    //   listData: [
    //     { title: 'Commitment to Safety',url: 'commitmentsafety' },
    //     { title: 'Safety Training',url: 'safetytrainnig' },
    //   ]
    // },
    {
      name: 'OES STANDARD', url: 'standard',
      listData: [
        // { title: 'Commitment to Safety',url: 'commitmentsafety' },
        // { title: 'Safety Training',url: 'safetytrainnig' },
      ]
    },
    {
      name: 'ABOUT US ', url: 'aboutus',
      listData: [
        { title: 'About Al Gulnar Al Fawah Trad and Cont',url:'aboutus' },
        { title: 'History',url:'aboutus' },
        { title: 'Contacts',url:'contactus' },
      ]
    },
  ]
  useEffect(() => {
    getProjectData()
    getService()
    getSafety()
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);
  const getProjectData = () => {
    ProjectService.findAll().then(res => {
      let obj = {
        name: 'Project', url: 'projects',
        listData: res
      }
      setProject(obj)
    }).catch(e => {

      let obj = {
        name: 'Project', url: 'projects'
      }
      setProject(obj)

      console.log(e)
    })
  }
  
  const getSafety = () => {
    SafetyService.findAll().then(res => {
      let obj = {        
        name: 'SAFETY', url: 'safety',
        listData: res
      }
      SetSafetyData(obj)
    }).catch(e => {

      let obj = {
        name: 'SAFETY', url: 'safety',
      }
      SetSafetyData(obj)

      console.log(e)
    })
  }
  
  const getService = () => {
    Service.findAll().then(res => {
      let obj = {        
        name: 'SERVICES ', url: 'service',
        listData: res
      }
      SetServiceData(obj)
    }).catch(e => {

      let obj = {
        name: 'SERVICES ', url: 'service',
      }
      SetServiceData(obj)

      console.log(e)
    })
  }
  document.addEventListener("DOMContentLoaded", function () {
    window.addEventListener('scroll', function () {
      if (window.scrollY > 141) {
        document.getElementById('navbar_top').classList.add('fixed-top');
      } else {
        document.getElementById('navbar_top').classList.remove('fixed-top');
      }

    });
  });
  function getLogout() {
    history.push('/login')
    window.location.reload()
  }
  const handleSelect = () => {
    setExpanded(!expanded);
    // Add any other logic you want to perform on menu item selection
  };

  const nextPage = (path) => {
    history.push(`/${path}`)
  }
  const listContent = (list) => {
    history.push(`/${list.url}?code=${list.code}`)
  }
  const goProject = (id) => {
    
    history.push(`/projectview?id=${id}`)
  }  
  
  const goSafety = (id) => {    
    history.push(`/viewSafety?id=${id}`)
  }  
  const goService = (id) => {    
    history.push(`/viewService?id=${id}`)
  }
  const closeDrawer = () =>{
    setOpen(false)
  }
  return (
    <>
      <div id="navbar_top" style={y > 141 ? { height: "141px" } : { height: "141px" }}>
        <Row className="top-menu">
          <div className="container" style={{ width: '100%', display: 'flex' }}>
            <Col span={12}>
              <PhoneOutlined className="phoneIcon" style={{ marginRight: 3, position: 'relative', top: 1 }} /> : <span style={{ marginLeft: 3 }} >+96891391971</span>
            </Col>
            <Col span={12} align={'end'}>
              <span className="mr-4"><MailOutlined style={{ marginRight: 3, position: 'relative', top: 1 }} /> : <span style={{ marginLeft: 3 }} >admin@agafoman.com</span></span>
              {/* <Link  style={{color:'#fff'}} onClick={()=>getLogout()}><UserOutlined className="mr-1" /> Login</Link> */}
            </Col>
          </div>
        </Row>
        <Navbar expanded={expanded} expand="lg"  className="navbar-light">
          <Row style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <Col onClick={() => history.push(`/`)}>
              <img href="/" src={Logo} className="img-fluid w-75" />
            </Col>
            <Col>
              <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={()=>setOpen(true)} />
              <div class="dropdown">
                <button onClick={() => nextPage(project.url)} class="dropbtn">{project.name} <CaretDownOutlined style={{ fontSize: 12 }} /></button>
                <ul class="dropdown-content">
                  {project && project.listData && project.listData.length > 0 && project.listData.map(list =>
                    <li onClick={() => goProject(list.id)} className="list-text">
                      <a>{list.subject || '  '}</a>
                    </li>
                  )}
                </ul>
              </div>
              <div class="dropdown">
                <button onClick={() => nextPage(serviceData.url)} class="dropbtn">{serviceData.name} <CaretDownOutlined style={{ fontSize: 12 }} /></button>
                <ul class="dropdown-content">
                  {serviceData && serviceData.listData && serviceData.listData.length > 0 && serviceData.listData.map(list =>
                    <li onClick={() => goService(list.id)} className="list-text">
                      <a>{list.subject || '  '}</a>
                    </li>
                  )}
                </ul>
              </div>
              
              <div class="dropdown">
                <button onClick={() => nextPage(safetyData.url)} class="dropbtn">{safetyData.name} <CaretDownOutlined style={{ fontSize: 12 }} /></button>
                <ul class="dropdown-content">
                  {safetyData && safetyData.listData && safetyData.listData.length > 0 && safetyData.listData.map(list =>
                    <li onClick={() => goSafety(list.id)} className="list-text">
                      <a>{list.subject || '  '}</a>
                    </li>
                  )}
                </ul>
              </div>
              {menuData.map(item => <div class="dropdown">
                <button onClick={() => nextPage(item.url)} class="dropbtn">{item.name} {item.url !== 'standard' && < CaretDownOutlined style={{ fontSize: 12 }} />}</button>
                <ul class="dropdown-content">
                  {item.listData.map(list =>
                    <li onClick={() => listContent(list)} className="list-text">
                      <a >{list.title || list.subject || '  '}</a>
                    </li>
                  )}
                </ul>
              </div>)}
            </Col>
            {/* <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto order-0" onSelect={handleSelect}>
            <Nav.Link as={NavLink} to="/" exact activeClassName="active">Home</Nav.Link>
            <Nav.Link as={NavLink} to="/aboutus" exact activeClassName="active">About Us</Nav.Link>
            <Nav.Link as={NavLink} activeClassName="active" to="/projects">Our Client & Projects</Nav.Link>
            <Nav.Link as={NavLink} to="/certifications" exact activeClassName="active">QHSE & Certification</Nav.Link>
            <Nav.Link as={NavLink} to="/contactus" exact activeClassName="active">Contact Us</Nav.Link>
          </Nav> */}
            {/* <Nav className="ml-auto">
            <Nav.Link href="javascript:;" onClick={()=>getLogout()} >
              <button class="lp-btn btn sm">Login</button>
            </Nav.Link>
          </Nav> */}
            {/* </Navbar.Collapse> */}
          </Row>
        </Navbar>
      </div>
      <Drawer className="mobileDrawer"  title={<img href="/" src={Logo} className="img-fluid w-75" />} width={280} onClose={() => setOpen(false)} open={open}>
        <MobileMenu closeDrawer={closeDrawer}/>
      </Drawer>
    </>
  )
}
