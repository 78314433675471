import React, { useState, useRef } from "react";
import { Form, Input, Button, Select, Row, Col, Upload } from "antd";
import axios from "axios";
import styled from "styled-components";
import rhsimg from "../../assets/img/2.png";
import Fade from 'react-reveal/Fade';
import Slider from "react-slick";
import ws1 from '../../assets/img/1.png';
import ws2 from '../../assets/img/2.png';
import ws3 from '../../assets/img/3.png';
import EmailService from "../../services/EmailService";
const { Option } = Select;

export default function Contactus() {
  const [formData, setFormData] = useState({
    name: "",
    contactNo: "",
    email: "",
    workingAt: "",
    officialEmail: "",
    officialAddress: ""
  });

  const handleFinish = async (values) => {
    try {
      const response = await EmailService.sendEmail(values);
      console.log('Form submitted successfully', response);
    } catch (error) {
      console.error('Error submitting form', error);
    }
  };

  const handleFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleFileUpload = ({ file }) => {
    setFormData((prevState) => ({
      ...prevState,
      file
    }));
  };

  const settings = {
    slidesToShow: 3,
    speed: 500,
    arrows: true,
    dots: true,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 1,
        centerPadding: "0%",
        arrows: true,
        dots: false,
      },
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerPadding: "0%",
        arrows: true,
        dots: false,
      }
    }]
  };

  var sliderImage = [
    { sliderimg: ws1 },
    { sliderimg: ws2 },
    { sliderimg: ws3 },
    { sliderimg: ws1 },
    { sliderimg: ws2 },
    { sliderimg: ws3 }
  ];

  return (
    <Wrapper id="aboutus" className="whiteBg">
      <div className="banner-sec-bg">
        <div className="container" style={{ margin: "0px auto 0 auto" }}>
          <div className="row align-items-center">
            <div className="col-md-6">
              <HeaderInfo>
                <h1 className="font40 extraBold whiteColor">Contact Us</h1>
              </HeaderInfo>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: "60px 0" }}>
        <section className="Material-contact-section section-padding section-dark">
          <div className="container content-wrapper">
            <div className="row">
              <div className="col-md-6 mt-3 contact-widget-section2 wow animated fadeInLeft" data-wow-delay=".2s">
                <h4 className="mb-4 extraBold">CORPORATE ADDRESS</h4>
                <div className="find-widget">
                  <a href="#" className="extraBold mb-2 d-block">AL GULNAR AL FAWAH TRAD. AND CONT. LLC</a>
                </div>
                <div className="find-widget">
                  <a href="#">Postal Code: 320 , Post Box: 765, <br />
                    Building No.490, Flat No.4, <br />
                    Al Juhaila, Barka<br />
                    Sultanate of OMAN.<br /></a>
                </div>
                <div className="find-widget">
                  Contact No:  <a href="#">91391971</a>
                </div>
                <div className="find-widget">
                  E-MAIL:  <a href="mailto:sales@agafoman.com">sales@agafoman.com</a>
                </div>
                <div className="mt-4">
                  <h4 className="mb-4 extraBold">SITE ADDRESS</h4>
                  <div className="find-widget">
                    <a href="#" className="extraBold mb-2 d-block">AL GULNAR AL FAWAH TRAD. AND CONT. LLC</a>
                  </div>
                  <div className="find-widget">
                    <a href="#">Postal Code: 320 , Post Box: 765, <br />
                      Building No.490, Flat No.4, <br />
                      Al Juhaila, Barka<br />
                      Sultanate of OMAN.<br /></a>
                  </div>
                  <div className="find-widget">
                    Contact No:  <a href="#">91391971</a>
                  </div>
                  <div className="find-widget">
                    E-MAIL:  <a href="mailto:sales@agafoman.com">sales@agafoman.com</a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <Form
                  name="contactForm"
                  onFinish={handleFinish}
                  onFinishFailed={handleFinishFailed}
                  layout="vertical"
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please enter your name' }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="contactNo"
                        label="Contact No"
                        rules={[{ required: true, message: 'Please enter your Contact No' }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Please enter your Email' }]}
                      >
                        <Input type="email" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="workingAt"
                        label="Working at"
                        rules={[{ required: true, message: 'Please enter where you work' }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="officialEmail"
                    label="Official Email"
                    rules={[{ required: true, message: 'Please enter your official email' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="officialAddress"
                    label="Official Address"
                    rules={[{ required: true, message: 'Please enter your official address' }]}
                  >
                    <Input.TextArea rows={3} />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
