import { Button, Divider, Select, Col, ConfigProvider, List, Form, Input, Row, message } from 'antd';

import React, { useState, useEffect, useRef } from 'react';
import UserService from '../../services/UserService';
import DocumentService from '../../services/DocumentService'
import { useHistory, useLocation } from 'react-router-dom';
import Icon, { CloseOutlined } from '@ant-design/icons';
import TextEditor from '../TextArea/TextArea'

const { Option } = Select; // Add this line to import Option

export default function AddUser() {
    const navigate = useHistory()
    const options = [
        { value: 'Open', label: 'Open' },
        { value: 'Completed', label: 'Completed' }
    ];
    const [UserForm] = Form.useForm();
    const [imageId, setImageId] = useState(null)
    const location = useLocation()
    const Params = new URLSearchParams(location.search);
    const [formDisabled, setFormDisabled] = React.useState(false);
    const save = (value) => {
        if (Params.get('id')) {
            value.id = Params.get('id')
        }
        value.name = `${value?.first_name||''} ${value?.last_name||''}`
        UserService.create(value).then(res => {
            if (res && res.data && res.data.data) {
                navigate.push(`/userList`)
                message.success("Save Successfully")
            }
            else {
                navigate.push(`/userList`)
                message.success("Update Successfully")

            }
        }).catch(e => {
            message.error("Something Went Wrong")
            console.log(e)
        })


    }
    useEffect(() => {
        if (Params.get('id')) {
            getById(Params.get('id'))
        }
        if(Params.get('type')=='view'){
            setFormDisabled(true)
        }

    }, [Params.get('type'), Params.get('id')])

    const getById = (id) => {
        UserService.findOne({ id: id }).then(async res => {
            UserForm.setFieldsValue(res ?res:{})

        }).catch(e => {
            console.log(e)
        })
    }

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#364ea2',
                    },
                    components: {
                        Tabs: {
                            itemActiveColor: '#1677ff',
                        },
                        Progress: {
                            circleTextFontSize: '0.666666666666667em',
                        },
                        Tag: {
                            defaultColor: ''
                        }
                    }
                }
                }
            >
                <Row>
                    <Col span={24}>
                        <h3>Add User</h3>
                        <CloseOutlined onClick={() => { navigate.push('/userList') }} style={{ float: 'right', cursor: 'pointer' }} />

                    </Col>
                </Row>
                <Divider plain></Divider>
                <Form
                    name="userForm"
                    onFinish={save}
                    autoComplete="off"
                    form={UserForm}
                    layout="vertical"
                    disabled={formDisabled}
                >

                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={11} sm={11} md={11} lg={13} xl={9}>
                            <Form.Item
                                name="first_name"
                                label={<div className='Label'>First Name</div>}
                                rules={[{ required: true, message: 'First Name Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                        <Col xs={11} sm={11} md={11} lg={13} xl={9} style={{ marginLeft: '100px' }}>
                            <Form.Item
                                name="last_name"
                                label={<div className='Label'>Last Name</div>}
                                rules={[{ required: true, message: 'Last Name Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0} style={{ width: '100%' }}>
                        
                    </Row>
                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                            <Form.Item
                                name="username"
                                label={<div className='Label'>Username</div>}
                                rules={[{ required: true, message: 'Username Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                            <Form.Item
                                name="password"
                                label={<div className='Label'>Password</div>}
                                rules={[{ required: true, message: 'PasswordS Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ marginRight: 50 }}>

                        <Col span={24} align="end" style={{ marginTop: 10 }}>
                            <Form.Item wrapperCol={{ offset: 1 }}>
                                <Button style={{ marginRight: '5px' }} onClick={() => { navigate.push(`/userList`) }} htmlType="button">
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </ConfigProvider>
        </>
    )
}