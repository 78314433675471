import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Space, Table, Row, Col, Tag } from 'antd';
import Button from 'react-bootstrap/Button';
import UserService from '../../services/UserService';
import { useLocation } from 'react-router-dom';
import icon, { PlusSquareOutlined } from '@ant-design/icons';



const User = () => {

  const history = useHistory();
  const location = useLocation();
  var stage = new URLSearchParams(location.search).get('stage');
  const columns = [

    // {
    //   title: 'Id',
    //   dataIndex: 'id',
    //   key: 'id',
    //   render: (text) => <a>{text}</a>,
    // },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'subject',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'subject',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'First Name',
      key: 'stage',
      dataIndex: 'first_name',
      render: (text) => <a>{text}</a>,
    },
    
    {
      title: 'Last Name',
      key: 'stage',
      dataIndex: 'last_name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => getAction(record.id, 'edit')}>Edit</a>
          <a onClick={() => getAction(record.id, 'view')}>View</a>
        </Space>
      ),
    },
  ];

  function getAction(id, type) {
    navigateToUserView(id, type);
  }

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getUserData();
  }, [stage]);

  const getUserData = () => {
    let obj = {}
    if (stage) {
      obj.stage = stage.toLowerCase() == 'open' ? 'Open' : 'Completed'
    }
    UserService.findAll(obj)
      .then((res) => {
        setUserData(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const openUser = (data) => {
    navigateToUserView(data.id);
  };

  const navigateToUserView = (userId, type) => {
    history.push(`/${type == 'edit' ? 'addUser' : 'addUser'}?id=${userId}&type=${type}`);
  };

  return (

    <Row >
      <Col span={24}>
        <Row >
          <Col span={16}>
            <h3>Users</h3>
          </Col>
          <Col span={8} align='right'>
            <PlusSquareOutlined onClick={() => history.push('/addUser')} style={{ fontSize: 30}} />
          </Col>
        </Row>

      </Col>
      <Col span={24} style={{ paddingTop: '40px' }}>
        <Table columns={columns} dataSource={userData} />
      </Col>
    </Row>


  );
};

export default User;
