import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Space, Table, Row, Col, Tag } from 'antd';
import Button from 'react-bootstrap/Button';
import ProjectService from '../../services/ProjectService';
import { useLocation } from 'react-router-dom';
import icon, { PlusSquareOutlined , DownloadOutlined } from '@ant-design/icons';
import ExportService from './ExportService';



const Project = () => {

  const history = useHistory();
  const location = useLocation();
  var stage = new URLSearchParams(location.search).get('stage');
  const columns = [

    // {
    //   title: 'Id',
    //   dataIndex: 'id',
    //   key: 'id',
    //   render: (text) => <a>{text}</a>,
    // },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Stage',
      key: 'stage',
      dataIndex: 'stage',
      render: (stage) => (
        <Tag color={stage === 'Open' ? 'green' : 'blue'} key={stage}>
          {stage}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => getAction(record.id, 'edit')}>Edit {record.name}</a>
          <a onClick={() => getAction(record.id, 'view')}>View</a>
        </Space>
      ),
    },
  ];

  function getAction(id, type) {
    navigateToProjectView(id, type);
  }
 

  const [projectData, setProjectData] = useState([]);

  useEffect(() => {
    getProjectData();
  }, [stage]);

  const getProjectData = () => {
    let obj = {}
    if (stage) {
      obj.stage = stage.toLowerCase() == 'open' ? 'Open' : 'Completed'
    }
    ProjectService.findAll(obj)
      .then((res) => {
        setProjectData(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const getDownloadData = async () =>{

    let column = [
      {
        field: "contract_no",
        header: "Contract / Tender No",
        style: { width: "100px", color: "" },
        toolTip: "Name",
        sortfield: true,
      },
      {
        field: "subject",
        header: "Project Name",
        style: { width: "100px", color: "" },
        toolTip: "Account Name",
        sortfield: true,
      },
      
      {
        field: "location",
        header: "Location",
        style: { width: "100px", color: "" },
        toolTip: "Num",
        sortfield: true,
      },        
      {
        field: "project_value",
        header: "Value",
        style: { width: "100px", color: "" },
        toolTip: "Num",
        sortfield: true,
      },
    ];
    await ExportService.export(projectData, "Project List", column, 'excel');
}

  const openProject = (data) => {
    navigateToProjectView(data.id);
  };

  const navigateToProjectView = (projectId, type) => {
    history.push(`/${type == 'edit' ? 'addprojects2' : 'viewProject2'}?id=${projectId}&type=${type}`);
  };

  return (

    <Row >
      <Col span={24}>
        <Row >
          <Col span={16}>
            <h3>Projects</h3>
          </Col>
          <Col span={8} align='right'>
            <DownloadOutlined onClick={() => getDownloadData()} style={{ fontSize: 30}} />
            <PlusSquareOutlined onClick={() => history.push('/addprojects2')} style={{ fontSize: 30}} />
          </Col>
        </Row>

      </Col>
      <Col span={24} style={{ paddingTop: '40px' }}>
        <Table columns={columns} dataSource={projectData} />
      </Col>
    </Row>


  );
};

export default Project;
