import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Col, Row, Button, Divider, Avatar } from 'antd';
import { useHistory } from 'react-router-dom';
import FooterProjectList from "./FooterProjectList";
import {
  UserOutlined,
  MailOutlined,
  InstagramOutlined,
  GoogleOutlined,
  UpOutlined,
  FacebookFilled,
  LinkedinFilled
} from '@ant-design/icons';
// Assets

import Logo from "../../assets/img/logo.jpg";
import ProjectService from "../../services/ProjectService";

export default function Footer() {
  const history = useHistory();
  const [project, setProject] = useState([])
  const [service, setService] = useState([
    { title: 'Engineering', code: 'engin',url:'/serviceView' },
    { title: 'Construction', code: 'constract',url:'/serviceView' },
    { title: 'Testing', code: 'test',url:'/serviceView' },
    { title: 'Service & Maintenance', code: 'mainteance',url:'/serviceView' },
  ])
  const addressData = `Al Gulnar Al Fawah Trad. & Cont. LLC
                      Building No.490
                      Flat No.4
                      Near to Lulu Hypermarket
                      Al Juhaila
                      Postal Code : 320
                      Barka
                      Sultanate of Oman.`

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }
  useEffect(() => {
    getProjectData()
  }, [])
  const getProjectData = () => {
    ProjectService.findAll().then(res => {
      setProject(res)
    }).catch(e => {
      setProject([])
      console.log(e)
    })
  }
  const goProject = (id) => {
    console.log(history, "0000000003333")
    history.push(`/projectview?id=${id}`)
  }
const nextMoreInfo =(path)=>{
  history.push(`/${path}`)
}
  return (
    <Wrapper>
      <div className="darkBg whiteColor footerLight">
        <div className="container">
          <Row gutter={[24, 8]} className="footer-top">
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <h2 className="footer-title">
                Locations
              </h2>
              <Row>
                <Col span={24}>
                  {/* <div className="location-title">Corporate Office</div> */}
                  <div >Al Gulnar Al Fawah Trad. & Cont. LLC
                                  <br />
                                  Building No.490, Flat No.4
                                  <br />
                                  Near to Lulu Hypermarket
                                  <br />
                                  Al Juhaila, 
                                  Postal Code : 320, 
                                  Barka
                                  <br />
                                  Sultanate of Oman.
                                  <br />
                                  <a href="https://www.google.com/maps/place/23%C2%B016'18.1%22N+56%C2%B033'06.4%22E/@23.2716907,56.5492062,17z/data=!3m1!4b1!4m4!3m3!8m2!3d23.2716907!4d56.5517811?entry=ttu"   target="_blank" className="location-url">23°26'25.9"N 57°25'42.2"E</a>

                    </div>
                </Col>
                <Col span={24}>
                  <div className="location-title">Rustaq camp</div>
                  <div>Contact No : +96891391971</div>
                  <div>Email : admin@agafoman.com</div>
                  <a href="https://www.google.com/maps/place/23%C2%B016'18.1%22N+56%C2%B033'06.4%22E/@23.2716907,56.5492062,17z/data=!3m1!4b1!4m4!3m3!8m2!3d23.2716907!4d56.5517811?entry=ttu"  target="_blank" className="location-url">23°26'25.9"N 57°25'42.2"E</a>

                </Col>
                <Col span={24}>
                  <div className="location-title">IBRI CAMP</div>
                  <div>Contact No : +96891391971</div>
                  <div>Email : admin@agafoman.com</div>
                  <a href="https://www.google.com/maps/place/23%C2%B016'18.1%22N+56%C2%B033'06.4%22E/@23.2716907,56.5492062,17z/data=!3m1!4b1!4m4!3m3!8m2!3d23.2716907!4d56.5517811?entry=ttu"  target="_blank" className="location-url">23°16'18.1"N 56°33'06.4"E</a>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <h2 className="footer-title">
                Projects
              </h2>
              <Row gutter={[4, 4]}>
                <FooterProjectList />
              </Row>



            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <h2 className="footer-title">
                Services
              </h2>
              <Row gutter={[4, 4]}>
              {service && service.length > 0 && service.map(key => (
                  <Col span={12} key={key.id}>
                    <a className="footerHover" href={`${key.url}?code=${key.code}`} style={{ color: 'inherit' }}>
                      {key.title}
                    </a>
                  </Col>
                ))}
                {/* <Col span={12}>Panel Design</Col>
                <Col span={12}>Construction</Col>
                <Col span={12}>Service &</Col>
                <Col span={12}>Design/Build</Col>
                <Col span={12}>Maintenance</Col>
                <Col span={12}>Testing/</Col>
                <Col span={12}>Emergency Service</Col> */}
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <h2 className="footer-title">
                More Information
              </h2>
              <Row gutter={[4, 4]}>
                <Col  span={24}><span href="/safety" className="footerHover">Safety</span></Col>
                <Col  span={24}><a href="/standard" className="footerHover">Oes Standard</a></Col>
                <Col  span={24}><a href="/aboutus" className="footerHover">About Us</a></Col>
                
              </Row>
            </Col>
          </Row>
          <Row style={{ paddingBottom: 30 }}>
            <Col span={12}>
              <LinkedinFilled style={{ fontSize: 30, marginRight: 20 }} />
              <FacebookFilled style={{ fontSize: 30, marginRight: 20 }} />
              <InstagramOutlined style={{ fontSize: 30, marginRight: 20 }} />
              <div style={{ marginTop: 20, fontSize: 13 }}>© 2024, Al Gulnar Al Fawah Trad and Cont. All rights reserved.</div>
            </Col>
            <Col align={'end'} span={12}>
              <img src={Logo} className="img-fluid w-15" />
            </Col>
          </Row>
          {/* <Row gutter={[16, 16]} className="footer-top">
            <Col xs={24} sm={12} md={8} lg={8} xl={8} align={'center'}>
              <Avatar className="footer-address" size={40} icon={<MailOutlined />} />
              <p className="addressList">(+91)9876543201</p>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8} align={'center'}>
              <Avatar className="footer-address" size={40} icon={<MailOutlined />} />
              <p className="addressList">hello@Messagegmail.com</p>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8} align={'center'}>
              <Avatar className="footer-address" size={40} icon={<MailOutlined />} />
              <p className="addressList">13/12 Anna Street Channai</p>
            </Col>
          </Row>
          <Divider style={{ backgroundColor: '#6d6d6d' }} />
          <Row style={{paddingTop:10}}>
              <Col xs={12} sm={12} md={8} lg={6} xl={6} align={'center'}>
                <div className="font14 extraBold" style={{ padding: "0px 0 10px",color:'#aaaaaa' }}>About AGAF</div>
                <ul className="lineHeight30">
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Contact Us</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Help & About us</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Contact</div></li>
                </ul>
              </Col>
          
              <Col xs={12} sm={12} md={8} lg={6} xl={6} align={'center'} >
                <div className="font14 extraBold" style={{ padding: "0px 0 10px",color:'#aaaaaa' }}>Our Projects</div>
                <ul className="lineHeight30">
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>On Going </div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Completed</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Yet to Start</div></li>
                </ul>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6} align={'center'}>
                <div className="font14 extraBold" style={{ padding: "0px 0 10px",color:'#aaaaaa' }}>Menus</div>
                <ul className="lineHeight30">
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Home</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>About Us</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Clients</div></li>
                </ul>
              </Col>
      
              <Col xs={12} sm={12} md={8} lg={6} xl={6} align={'center'} >
                <div className="font14 extraBold" style={{ padding: "0px 0 10px",color:'#aaaaaa' }}>Construction</div>
                <ul className="lineHeight30">
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Documentation</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Block explorer</div></li>
                  <li><div clasName="font12" style={{ color: '#6e6e6e'}}>Status</div></li>
                </ul>
              </Col>
          </Row>     */}
          {/* <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>

            <StyleP className="whiteColor font15">
              © {getCurrentYear()} - <span className="purpleColor font15">AGAF </span> All Right Reserved
            </StyleP>

            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              Back to top
            </Link>
          </InnerWrapper> */}
        </div>
        {/* <Row gutter={[16, 16]} style={{backgroundColor:'rgb(5 16 41)',padding:20,marginLeft:0,marginRight:0}}>
            <Col  xs={24} sm={12} md={7} lg={8} xl={8} align={'center'} >
            <StyleP className="whiteColor font15" style={{paddingTop:7}}>
              © {getCurrentYear()} - <span className="purpleColor font15">AGAF </span> All Right Reserved
            </StyleP>
            </Col>
            <Col  xs={24} sm={12} md={10} lg={8} xl={8} align={'center'} >
            <Avatar style={{border:'1',borderColor:'#838383',marginLeft:25}} icon={<InstagramOutlined />} />
            <Avatar style={{border:'1',borderColor:'#838383',marginLeft:25}} icon={<GoogleOutlined />} />
            <Avatar style={{border:'1',borderColor:'#838383',marginLeft:25}} icon={<InstagramOutlined />} />
            <Avatar style={{border:'1',borderColor:'#838383',marginLeft:25}} icon={<GoogleOutlined />} />
            </Col>
            <Col  xs={24} sm={12} md={7} lg={8} xl={8} align={'center'} >
            <Avatar style={{border:'1',borderColor:'#838383'}} icon={<UpOutlined />} />
            </Col>
          </Row> */}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
const CustomCol = styled.col`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;