import React from "react";
import { useEffect, useState } from 'react';
import styled from "styled-components";
import { Row, Col, Button } from 'antd';
// Assets
import developerImg from "../../assets/img/banner4.jpg";
import { Fade } from "react-reveal";
import { useHistory } from 'react-router-dom';
import ProjectService from '../../services/ProjectService';

export default function TestingandCommissioning() {
  const history = useHistory();
  const [cardData, setCardData] = useState([])
  const viewProject = (value) => {
    if (value && value.id) {

      history.push(`/projectview?id=${value.id}`)
    }
  }
  useEffect(() => {
    getProjectDataWithUrl();
  }, []);
  const getProjectDataWithUrl = () => {
    ProjectService.findAllWithUrl()
      .then((res) => {
        console.log(res, "000000000022233w")
        setCardData(res && res.length > 0 ? res : [])
      })
      .catch((e) => {
        setCardData([])
        console.log(e);
      });
  };


  return (
    <Wrapper id="developer">
     
      <p className="font18 mb-3"> <Heading>Broad expertise in virtually every type of electrical system.</Heading></p>
      <Row gutter={40}>
        {cardData && cardData.length > 0 && cardData.map(item => <Col xs={24} sm={24} md={12} lg={8} xl={8}>
          <div className="row projetCard textCenter mb-5">
            <div onClick={viewProject} class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img style={{ width: '100%', height: 260 }} src={item.url} alt="Avatar" />
                  <h5>{item?.subject || ''}</h5>
                </div>
                <div class="flip-card-back">
                  <Button className="projectViewbutton" onClick={() => viewProject(item)}>View More</Button>
                </div>
              </div>
            </div>
          </div>
        </Col>
        )}
      </Row>
      {/* <div className="lightBg">
        <div className="container">       
          <div className="row flexCenter" style={{ paddingBottom: "100px" }}>
            <div className="col-xs-12 col-sm-12 col-md-8">
              <Fade left>
              <HeaderInfo>
                <h1 className="font40 extraBold" style={{ padding: "35px 0" }}>TESTING AND <span className="highlight-txt"> COMMISSIONING.</span></h1>
                  <p className="font18 lineHeight40">
                  AGAF have a specialized testing team with DCRP approved Testing Engineers and high precision advanced testing equipments,  Electrical testing is needed for electrical equipment functioning properly after installation and has become a key component for equipment safety to include performance, operations, and energy requirements for electrical facilities.  AGAF does the quality-oriented process for achieving, verifying, and documenting the performance of individual electrical equipment, controlling devices as well as systems that meet defined objectives and criteria. We ensure that the system is safe and meet the design requirements
                </p>
            </HeaderInfo>
            </Fade>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-4">           
              <div style={{ width: "100%"}}>
                <div style={{ marginTop: "50px"}}>
                  <img src={developerImg} alt="office" className="img-fluid rounded"/>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const Heading = styled.h2`
    font-size: 1.625rem;
    font-weight: 600;
    margin: 50px 0px 10px 0px;
}
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;









