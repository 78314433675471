import React from "react";
import { useEffect, useState } from 'react';
import { Button, Flex, Carousel } from 'antd';
import { Row, Col } from 'antd';
import styled from "styled-components";
// Components
import rhsimg from "../../assets/img/banner3.jpg";
import secondImage from '../../assets/img/banner2.jpg'
import thirdImage from '../../assets/img/banner5.jpg'
import ProjectService from '../../services/ProjectService';
import { useHistory, useLocation } from 'react-router-dom';

export default function ProjectOverView() {
  const location = useLocation()
  const Params = new URLSearchParams(location.search);

  useEffect(() => {
    getProjectDataWithUrl();
  }, [Params.get('id')]);
  const getProjectDataWithUrl = () => {
    ProjectService.getOneSubProject({ id: Params.get('id') })
      .then((res) => {
        setSubProjectData(res && res.length > 0 ? res[0] : {});
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const [subProjectData, setSubProjectData] = useState([])

  const listData = [
    { name: 'Project', subName: 'Palisades Nuclear Plant' },
    { name: 'Type', subName: 'Power Generation' },
    { name: 'Location', subName: 'Covert, Michigan' },
    { name: 'Role', subName: 'Electrical Contractor' },
  ]
  return (
    <Wrapper id="projects">
      <div className="">
        <div style={{ backgroundImage: "url(" + `${subProjectData && subProjectData.urlData && subProjectData.urlData.length > 0 ? subProjectData.urlData[0] : ''}` + ")", height: 350, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>
          <div style={{ width: '100%', height: '100%', background: 'black', opacity: 0.5, display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#fff' }}>
            <h2 className="corousal-header"><strong>{subProjectData?.subject || ''}</strong></h2>
          </div>
          {/* <div className="col-md-6 textRight">
                <img src={bannerImg} className="img-fluid allapps-banner"/>
              </div> */}
        </div>
        <div className="container content-wrapper" style={{ margin: "25px 80px 0px auto" }}>
          <Row gutter={20} className="mb-5">
            <Col span={24}>
              <h3 className="font16 my-2"><strong>Project Overview</strong></h3>
              <Row gutter={40}>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                  <div  dangerouslySetInnerHTML={{ __html: subProjectData?.description || '' }} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <div> <span style={{ fontSize: 16, fontWeight: 700 }}>Project:</span><span style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>{subProjectData?.subject || ''}</span></div>
                  <div> <span style={{ fontSize: 16, fontWeight: 700 }}>Type:</span><span style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>{subProjectData?.main_project_name || ''}</span></div>
                  <div> <span style={{ fontSize: 16, fontWeight: 700 }}>Location:</span><span style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}>{subProjectData?.location || ''}</span></div>
                  <div> <span style={{ fontSize: 16, fontWeight: 700 }}>Role:</span><span style={{ fontSize: 14, fontWeight: 500, marginLeft: 5 }}></span></div>
                </Col>
              </Row>
            </Col>
          </Row>
          <h3 className="mb-3"><strong>Photo Gallery</strong></h3>
          <Row gutter={[16, 16]}>
            {subProjectData && subProjectData && subProjectData.urlData && subProjectData.urlData.length > 0 ? (
              subProjectData.urlData.map((key, index) => (
                <Col xs={24} sm={24} md={12} lg={6} xl={6} style={{ height: 250 }}>
                  <img src={key} alt="" style={{ width: '100%', height: '100%' }} />
                </Col>
              ))
            ) : <></>}
            
          </Row>
          <div className="row projetCard textCenter mb-5">
            {/* <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <img style={{width:400,height:260}} src={p1} alt="Avatar" />
                  <h5>Power Generation</h5>
                </div>
                <div class="flip-card-back">
                <Button className="projectViewbutton">View More</Button>
                </div>
              </div>
            </div> */}
            {/* {AllappsData.map((app, index) => (
            <div style={{padding:10}} className="col-xs-12 col-sm-4 col-md-4 col-lg-3" key={index}>
             <Bounce>
             
              <Wrapper className="radius8 shadow all-apps-card h-100"> */}
            {/* <div className="flex flexNullCenter flex-end">
                  <div><img src={liveIcon} alt="office" className="img-fluid" /></div>
                  <div className="font16 primaryColor">Live Projects</div>
                </div> */}
            {/* <div className="h-100">
                  <div className="project-header">
                  {app.url ? <img src={app.url} alt="" className="img-fluid appsImage" /> : <></>}
                  </div>
                  <div className="p-4">
                  <div className="font15 extraBold mb-0">Title</div>
                   <div className="font14 mb-0 bridge-title">{app.subject}</div>
                  </div>                 
                  {/* <div className="">{app.url}</div> 
                </div> */}
            {/* <Fade bottom>
                  <p className="bridge-txt" style={{ padding: "30px 0" }}>
                    {app.description}
                  </p>
                </Fade> */}
            {/* </Wrapper>
              </Bounce>
            </div>
          ))} */}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
width: 100%;
text-align: left;
`;
const HeaderInfo = styled.div`
  color:#000;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
