import { useHistory } from 'react-router-dom';
import ProjectService from "../../services/ProjectService";
import { Col, Row, Button, Divider, Avatar } from 'antd';
import React, { useEffect, useState } from "react";

export default function FooterProjectList() {
    const [project, setProject] = useState([])
    const history = useHistory();
    useEffect(() => {
        getProjectData()
      }, [])
      const getProjectData = () => {
        ProjectService.findAll().then(res => {
          setProject(res)
        }).catch(e => {
          setProject([])
          console.log(e)
        })
      }
    return (
        <>
          {project && project.length > 0 && project.map(key => (
                  <Col span={12} key={key.id}>
                    <a className="footerHover" href={`https://agafoman.com/projectview?id=${key.id}`} style={{ color: 'inherit' }}>
                      {key.subject}
                    </a>
                  </Col>
                ))}
        </>
    )
}