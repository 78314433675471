import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { Form } from 'antd';
import 'react-quill/dist/quill.snow.css';

function TextArea({ form }) {
    const [editorHtml, setEditorHtml] = useState('');

    // Handle editor content change
    const handleEditorChange = (html) => {
        setEditorHtml(html);
        form.setFieldsValue({ description: html }); // Update form field value
    };

    const quillModules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            matchVisual: false,
        }
    };

    const quillFormats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];

    return (
        <Form.Item
            name="description"
        >
            <ReactQuill
                theme="snow"
                value={editorHtml}
                onChange={handleEditorChange}
                modules={quillModules}
                formats={quillFormats}
            />
        </Form.Item>
    );
}

export default TextArea;
