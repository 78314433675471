import { Row, Col } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DocumentService from '../../services/DocumentService'
import icon, { PlusSquareOutlined } from '@ant-design/icons';
import ProjectService from '../../services/ProjectService';
import Icon, { CloseOutlined, EditOutlined } from '@ant-design/icons';

export default function ProjectView() {
    const location = useLocation()
    const history = useHistory();
    const Params = new URLSearchParams(location.search);
    const [projactData, setProjectData] = useState({})
    const [subProjectData, setSubProjectData] = useState([])
    useEffect(() => {
        getProjectData()
    }, [Params.get('id')])
    const getProjectData = () => {
        ProjectService.getProjectList({ projectId: Params.get('id') }).then(async res => {
            setProjectData(res && res.length > 0 ? res[0] : {})
            let $subProjectData = res && res.length > 0 && res[0].sub_project_data ? JSON.parse(res[0].sub_project_data) : []
            for (let i = 0; i < $subProjectData.length; i++) {
                let attachement = $subProjectData[i] && $subProjectData[i].attachments ? JSON.parse($subProjectData[i].attachments) : []
                if (attachement && attachement.length > 0) {
                    $subProjectData[i].url = await getImageData(attachement[0])
                }

            }
            setSubProjectData($subProjectData)
        }).catch(e => {
            setProjectData({})
            console.log(e)
        })
    }
    async function getImageData(id) {
        try{
            let res = await DocumentService.imageProfile({ id: parseInt(id) });
            console.log(res,"hhhhhhhhhhheeee")
            return res || null
        }
        catch(e){
            console.log(e);
            return null
        }
    }
    const FirstList = [
        { label: 'Traditional Generation' },
        { label: 'Renewable Generation' },
        { label: 'Gas' },
        { label: 'Hydroelectric' },
        { label: 'Nuclear' },
        { label: 'Industrial Cogeneration' },
    ]
    const overViewProject = (value) => {
        history.push(`/projectoverview?id=${value.id}`)
    }

    const ViewSubProject = (value) => {
        history.push(`/addSubProject?id=${value.id}&mainId=${Params.get('id')}`)
    }
    const handleClick = () => {
        history.push(`/addSubProject?mainId=${Params.get('id')}`);
    };

    return (
        <>
            <Row>
                <Col span={12} offset={12}>
                    <CloseOutlined onClick={() => { history.push('/listproject2') }} style={{ float: 'right', cursor: 'pointer', fontSize: 30 }} />

                </Col>
            </Row>
            <h2><strong> {projactData?.subject || ''} </strong></h2>
            <Row style={{ padding: 25 }}>
                <Col span={12}>
                    <div dangerouslySetInnerHTML={{ __html: projactData?.description || '' }} />

                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <h3>Sub Project</h3>

                </Col>
                <Col span={2}>
                    <PlusSquareOutlined onClick={handleClick} style={{ cursor: 'pointer', fontSize: 30 }} />
                </Col>
            </Row>
            <Row>
                {subProjectData && subProjectData.length ? (
                    subProjectData.map((key, index) => (
                        <Col span={8} key={index}>
                            <div className="box-wrapper">
                                <figure className="shape-box shape-box_half">
                                    <img src={key.url} alt="" />
                                    <div className="brk-abs-overlay z-index-0 bg-black opacity-60"></div>
                                    <figcaption>
                                        <div className="show-cont">
                                            <h3 className="card-no">{index + 1} </h3>
                                            <h4 className="card-main-title">{key.subject}</h4>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: key?.location || '' }} />
                                        <a className="read-more-btn" onClick={() => overViewProject(key)}>Read More</a>
                                        <EditOutlined onClick={() => ViewSubProject(key)} style={{ marginLeft: 10, marginBottom: 2, fontSize: 30, cursor: 'pointer' }} />
                                    </figcaption>
                                    <span className="after"></span>
                                </figure>
                            </div>
                        </Col>
                    ))
                ) : <></>}


            </Row>
        </>
    )
}