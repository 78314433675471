import axios from "axios";
import config from './config.json'
var MainService={
    async getResult(data){
        let token = localStorage.getItem('TOKEN')

        data.url = config.apiUrl + data.url;
        // if(data.method=='get'){
        //    return await  axios.get(data.url, {params: data.params});
        // }else if(data.method=='post'){
        //     return await axios.post(data.url, data.data)
        // }


        if (data.method === 'get') {
            let $paramData ={
                params:data.params
            }
            if(token){
                $paramData.headers = {
                    'Authorization': token,
                    'Content-Type': 'application/json' 
                }
            }
            return await axios.get(data.url,$paramData);
        } else if (data.method === 'post') {
            let $header = {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json' // Example header, you can add more as needed
                }
            }

            return await axios.post(data.url, data.data,$header);
        }
    }
};
export default MainService;