// services/ExportService.js

import * as xlsx from 'xlsx';
import * as FileSaver from 'file-saver';
import lodash from 'lodash';

class ExportService {
    async export(sourceData, fileName, layoutColumns, type) {
        let $fileName = fileName ? fileName : '';
        if (type.toLowerCase() === 'excel') {
            await this.exportExcel(sourceData, $fileName, layoutColumns);
        }
    }

    async exportExcel(sourceData, fileName, layoutColumns) {
        let data = await this.formattedData(sourceData, layoutColumns);
        const worksheet = xlsx.utils.json_to_sheet(data);

        // Apply styles to headers
        const headerRange = xlsx.utils.decode_range(worksheet['!ref']);
        for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
            const cellAddress = xlsx.utils.encode_cell({ c: C, r: 0 });
            if (!worksheet[cellAddress]) continue;
            worksheet[cellAddress].s = {
                font: { bold: true },
                fill: { fgColor: { rgb: "FFFF00" } }, // Yellow background
                border: {
                    top: { style: "thin", color: { rgb: "000000" } },
                    bottom: { style: "thin", color: { rgb: "000000" } },
                    left: { style: "thin", color: { rgb: "000000" } },
                    right: { style: "thin", color: { rgb: "000000" } }
                }
            };
        }

        if (data && data.length > 0) {
            worksheet['!cols'] = [];
            Object.keys(data[0]).forEach(() => {
                let width = { width: 20 };
                worksheet['!cols'].push(width);
            });
        }

        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, fileName);
    }

    formattedData(dataSource, layoutColumns) {
        let selectedColumns = layoutColumns;
        let data = JSON.parse(JSON.stringify(dataSource));
        let $data = JSON.parse(JSON.stringify(dataSource));
        let formateData = [];
        for (let i = 0; i < $data.length; i++) {
            let $obj = {};
            for (let j = 0; j < selectedColumns.length; j++) {
                if (selectedColumns[j]['header'].length > 0) {
                    if ((selectedColumns[j]['type'] === 'date' || selectedColumns[j]['type'] === 'datetime' || selectedColumns[j].field === 'created' || selectedColumns[j].field === 'modified')) {
                        $obj[selectedColumns[j]['header']] = this.formatDate(lodash.get(data[i], selectedColumns[j].field), selectedColumns[j].field);
                    } else if (selectedColumns[j].field === 'active' || selectedColumns[j].header.toLowerCase() === 'status') {
                        $obj[selectedColumns[j]['header']] = data[i].active === 1 ? true : false;
                    } else if (selectedColumns[j]['header'].toLowerCase() !== 'action') {
                        $obj[selectedColumns[j]['header']] = lodash.get(data[i], selectedColumns[j].field);
                    }
                }
            }
            formateData.push($obj);
        }
        return formateData;
    }

    saveAsExcelFile(buffer, fileName) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    formatDate(dateString, type) {
        if (!dateString) {
            return '';
        } else if (type === 'date') {
            const date = new Date(dateString);
            return date.toLocaleDateString();
        } else {
            const date = new Date(dateString);
            return date.toLocaleString();
        }
    }
}

export default new ExportService();
