import React from "react";
import styled from "styled-components";
import { Row, Col ,Button} from 'antd';
import developerImg from "../../assets/img/rightArrow.png";
// Assets
import joinusImg from "../../assets/img/banner5.jpg";
import Fade from 'react-reveal/Fade';
// Assets

const listData = [
  {name:'Newkirk Receives CET Gold Award from MIOSHA',description:'Al Gulnar Al Fawah Trad and Cont is pleased to announce that we were awarded a Consultation Education and Training (CET) Gold Award from MIOSHA. Awards are presented to Michigan employers with excellent safety and health performance and includes an emphasis on proactive safety practices.'},
  {name:'Delta Energy Park Recognized as an ENR Best Project Winner',description:'ENR Midwest completed its assessment of eligible projects for their Best Projects Awards and the Delta Energy Park, a significant project for Al Gulnar Al Fawah Trad and Cont, was named winner of the Energy/Industrial category. Al Gulnar Al Fawah Trad and Cont partnered with Barton Malow and the Lansing Board of Water and Light to complete this impressive new 250MW natural gas-fired power plant. The Delta Energy Park was one of the most significant projects in Newkirk s 60+ year history.'},
  {name:'Newkirk Makes Solar Power World s 2022 Top Solar Contractors',description:'Al Gulnar Al Fawah Trad and Cont is listed in the top Solar Power Contractors, ranked by installed capacity in 2021.'},
]

export default function NewsPage() {
  return (
    <Wrapper id="governance" className="mb-5">
         <Heading>News</Heading>
       <Row gutter={32}>
       {listData.map(item =>  <Col xs={24} sm={24} md={12} lg={8} xl={8}>
             <SubHeading>{item.name}</SubHeading>
             <p style={{fontSize:16}} className=" mb-3">{item.description}</p>
             <ul style={{listStyle:'none'}}>
              <List className="mb-3 activeLink">
                <img className="linkList" src={developerImg} alt="Avatar" /><a className="listName" href="">Read</a>
                </List>
               </ul>
        </Col>
        )}
       </Row>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const Heading = styled.h2`
    font-size: 1.625rem;
    font-weight: 600;
    margin: 0px 0px 6px 0px;
`;
const List = styled.li`
    color:#DB373E;
    font-size:16px;
    font-weight: bold;
`;
const SubHeading = styled.h5`
color:#DB373E;
font-weight: 600;
margin: 0px 0px 6px 0px;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;