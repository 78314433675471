import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import NoAccessImg from '../assets/img/NoAccess.svg';

const NoAccess = () => {
    const history = useHistory();

    const handleHomeClick = () => {
        history.push('/');
        window.location.reload()
    };

    const handleLoginClick = () => {
        history.push('/login');
        window.location.reload()
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center', margin: 50 }}>
                <h1>No Access</h1>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', margin: 50 }}>
                <img src={NoAccessImg} alt="No Access" height={400} width={400} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="primary" onClick={handleHomeClick} style={{ margin: 50 }}>Home</Button>
                {/* <Button type="primary" onClick={handleLoginClick} style={{ margin: 50 }}>Login</Button> */}
            </div>
        </>
    );
};

export default NoAccess;
