import { Row, Col } from 'antd';
import thirdImage from '../../assets/img/slider-2.jpg';
import styled from "styled-components";
import SafetyService from '../../services/SafetyService';
import PolicyService from '../../services/PolicyService';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';

export default function ViewSafety() {
    const history = useHistory();
    const location = useLocation();
    const [safetyData, setSafetyData] = useState({});
    const [policyData, setPolicyData] = useState([]);
    const [logged, setLogged] = useState(null);
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        let loggedUser = localStorage.getItem('USER_DETAILS')
        setLogged(loggedUser?true:false)
        getSafetyData();
        getPolicyData();
    }, [params.get('id')]);

    const getSafetyData = () => {
        SafetyService.getSafetyList({ safetyId: params.get('id') }).then(res => {
            console.log(res, 'res dataaaaaaaa');
            setSafetyData(res && res.length > 0 ? res[0] : {});
        }).catch(e => {
            setSafetyData({});
            console.log(e);
        });
    };
    const getPolicyData = () =>{
        PolicyService.findAll({ active:1 }).then(res => {
            console.log(res, 'policy dataaaaaaaa');
            setPolicyData(res && res.length > 0 ? res : []);
        }).catch(e => {
            setPolicyData([]);
            console.log(e);
        });
    }

    const selectSafety = (code) => {
        history.push(`/safetyView?code=${code}`);
    };

    return (
        <>
            <div style={{
                backgroundImage: `url(${safetyData?.url || ''})`,
                height: 450,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}>
                <div style={{
                    width: '100%',
                    height: '100%',
                    background: 'black',
                    opacity: 0.5,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff',
                }}>
                    <h1 className="corousal-header"><strong>{safetyData?.subject || ''}</strong></h1>
                </div>
            </div>
            <div className="container my-5 content-wrapper">
                <h3>{safetyData?.subject || ''}</h3>
                <Row style={{ fontSize: 16, marginTop: 15 }}>
                    <Col className='viewProject' xs={24} sm={24} md={24} lg={24} xl={24} dangerouslySetInnerHTML={{ __html: safetyData?.description || '' }} />
                </Row>
                {logged?
                <Row style={{ fontSize: 16, marginTop: 15 }}>
                    <Col className='viewProject' xs={24} sm={24} md={24} lg={24} xl={24}>
                        <FlexWrapper>
                            <h2><b>Add Policy</b></h2>
                            <PlusCircleOutlined  onClick={() => history.push('/addpolicy')} style={{ fontSize: '24px', marginLeft: '10px' }} />
                        </FlexWrapper>
                    </Col>
                </Row>:<></>}
                {safetyData.subject && safetyData.subject =='NAMA Distribution Electrical Safety Rules'?
                <Row style={{marginTop:40}}>
                {policyData && policyData.length > 0 ? policyData.map((safetyItem, index) => (
                    <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12}>
                        <h3>{safetyItem?.name||''}</h3>
                        <p style={{ fontSize: 16, marginTop: 8, marginBottom: 10 }}>
                            {safetyItem?.description||''}
                        </p>
                        <ul style={{ listStyle: 'none' }}>
                            <List className="mb-5 activeLink">
                                {/* Assuming you have a URL for the safety letter */}
                                <a href={safetyItem.link} target='_blank' className='listName'>{safetyItem.link_name}</a>
                            </List>
                        </ul>
                    </Col>
                )) : <></>}
                </Row>:<></>    
            }
                
            </div>
        </>
    );
}

const FlexWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const List = styled.li`
    color: #DB373E;
    padding: 4px 0;
    font-weight: bold;
`;
