import React from "react";
import styled from "styled-components";
import rhsimg from "../../assets/img/banner4.jpg";
import Fade from 'react-reveal/Fade';
import Slider from "react-slick";
import ws1 from '../../assets/img/banner3.jpg';
import ws2 from '../../assets/img/banner5.jpg';
import ws3 from '../../assets/img/banner1.jpg';
import { Col, Row } from 'antd';
import {
  CheckOutlined
} from '@ant-design/icons';
export default function Aboutus() {
  const settings = {
    slidesToShow: 3,
    speed: 500,
    arrows: true,
    dots: true,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 1,
        centerPadding: "0%",
        arrows: true,
        dots: false,
      },
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerPadding: "0%",
        arrows: true,
        dots: false,
      }
    }]
  };

  var sliderImage = [
    { sliderimg: ws1 },
    { sliderimg: ws2 },
    { sliderimg: ws3 },
    { sliderimg: ws1 },
    { sliderimg: ws2 },
    { sliderimg: ws3 }
  ]
  var whatOffer =[
    {description:'Erection & commissioning of 33/11 KV Outdoor and Indoor Primary Substations.'},
    {description:'Erection & Commissioning of various types of Distribution substation like Compound Wall Substations, Package Substations, GRP covered Substations.  Cage Substations for industrial purpose.'},
    {description:'Erection & Commissioning of MV panels, Transformers, Auto Reclosers, Ring Main Units, Feeder Pillars, Cut-out Boxes, HT & LT Meter Panel, and all type of industrial switchgears and specialized electrical equipments.'},
    {description:'Upgrading of Primary Substations, Capacity Enhancement of Transformers etc.  Rehabilitation work on Existing substation.'},
    {description:'Erection of GIS and AIS Switch Gears, Control and Protection Panels, Reactors, Capacitor Banks, Instrument Transformer, Power Quality Meters, Erection of Bus Ducts, Control Cable laying etc.,.'},
    {description:'Installation of Earthing and Lightning Protection System.'},
    {description:'Construction of OH Line up to 33kV on Lattice Steel tower/Concrete pole/Wooden pole - Stringing of Conductors (AAAC, ACSR) with specialized tools and equipments.'},
    {description:'Cable Laying up to 33KV with specialized Pulling machines and standardized Tools for application of Jointing & Termination Works with DCRP approved License Holders.'},
    {description:'Maintenance projects for substations, transformers, and switch gears.'},
    {description:'Industrial electrification and maintenance projects.'},
    {description:'Fibre Optic Cabling and associated network jobs.'},
    {description:'Service of licensed and approved cable Jointers.'},
    {description:'Streetlight Power Electrification works and Street Light installation and related Civil works.'},
  ]
  return (
    <Wrapper id="aboutus" className="whiteBg">
      <div className="banner-sec-bg">
        <div className="container" style={{ margin: "0px auto 0 auto" }}>
          <div className="row align-items-center">
            <div className="col-md-6">
              <HeaderInfo >
                <h1 className="font40 extraBold whiteColor">About Us</h1>
              </HeaderInfo>
            </div>
            {/* <div className="col-md-6 textRight">
                <img src={bannerImg} className="img-fluid allapps-banner"/>
              </div> */}
          </div>
        </div>
      </div>
      <div style={{ padding: "60px 0" }}>
      <div className="container content-wrapper">
      <div className="row align-items-center">
            <div className="col-lg-12">
              <Fade top>
                <HeaderInfo>
                  {/* <h1 className="font30 extraBold text-uppercase" style={{ padding: "35px 0" }}>About Us</h1> */}
                  <p className="font20 lineHeight30 mb-4">
                    AGAF LLC is one of the DCRP certified B Grade Contractors in Oman and authorized to work on all electrical Assets up to 33KV.  The company is registered with Ministry of Commerce and industry as 1st grade Excellent service provider, ISO 9001:2015, ISO 45001:2018, ISO 14001:2015,  More over AGAF have 17 + Years of Local Experience and have the project setup of Fast Mobilization Capability.   Our Site Office is set up in North Batinah (Sohar), South Batinah (Muladha), Dahirah (Ibri). we have established our services almost in all regions of oman,  A separate electrical team has been formed to handle projects in the private all over oman. Our team executes electrical installation, testing & commissioning for civil, road, infrastructure contractors, and other private clients. AGAF’s commitment to providing a professional service, economically, timeously, and with consistency is the key to our enviable growth record
                  </p>
                </HeaderInfo>
              </Fade>
            </div>
          </div>
          </div>
        <div className="lightBg py-5 my-5">
          <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div>
                <img src={rhsimg} className="img-fluid" alt="Service" />
              </div>

            </div>
            <div className="col-lg-8">
              <Fade right>
                <HeaderInfo>
                  <h1 className="font30 extraBold text-uppercase" style={{ padding: "35px 0" }}>OUR MISSION</h1>
                  <p className="font20 lineHeight30">
                    To improve continuously in the areas of Engineering Services towards our stake holders with constructive KPI’s Tools and to be the most economical electrical service provider to the customer and maintaining higher level of QHSE standards.
                  </p>
                </HeaderInfo>
              </Fade>
            </div>
          </div>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <Fade left>
                <HeaderInfo>
                  <h1 className="font30 extraBold text-uppercase" style={{ padding: "35px 0" }}>OUR VISSION</h1>
                  <p className="font20 lineHeight30">
                    AGAF - Vision is to be the Highly reputed and Expert in Oman Electrical Distribution Field and to be leading service provider with epdimic growth and building its path through out Middle East.
                  </p>
                </HeaderInfo>
              </Fade>
            </div>
            <div className="col-lg-4">
              <div>
                <img src={rhsimg} className="img-fluid" alt="Service" />
              </div>
            </div>
          </div>
         
        </div>
        <div className="lightBg py-5 my-5">
        <div className="container">
        <h1 className="font30 extraBold text-uppercase" style={{ padding: "35px 0" }}>CORE VALUES</h1>
          
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">            
              <div className="shadow whiteColor radius8 card-wrapper h-100">
                <div className="">
                    <h4 className="font30 extraBold mb-4">Engineering Excellence</h4>
                    <p className="font20 lineHeight30">
                    We continuously improve our work by bettering our understanding of our client’s requirements. We endeavour to exceed expectations in every project.
                    </p>
                </div>
               
              </div>
             
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="shadow whiteColor radius8 card-wrapper h-100">
                <div className="">
                    <h4 className="font30 extraBold mb-4">Continuous Improvement</h4>
                    <p className="font20 lineHeight30">
                    We are committed to getting better every day, both as individuals and as a team. We work with enthusiasm and are driven to surpass what has already been achieved.   
                    </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="shadow whiteColor radius8 card-wrapper h-100">
                <div className="">
                  <h4 className="font30 extraBold mb-4">Teamwork</h4>
                    <p className="font20 lineHeight30">
                    Our success can primarily be attributed to the collaborative & supportive environment we maintain. Our teams are driven by collaboration, respect and accountability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

        <div className="container">
          <h1 className="font30 extraBold text-uppercase" style={{ padding: "35px 0" }}>WORK SHOP</h1>
          <div className='row banner-subpage slider-section'>
            <div class="slider">
              <div class="slide">
                <Slider {...settings}>

                  {sliderImage.map(item => (
                     <div>
                      <div className="slider-card">
                        <img src={item.sliderimg} className="img-fluid" style={{height:300}} />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container">
          <h1 className="font30 extraBold text-uppercase" style={{ padding: "35px 0" }}>WHAT WE OFFER</h1>
          <div className='row banner-subpage slider-section'>
                  {whatOffer.map(item => (
                     <Row style={{marginTop:15}}>
                      <Col span={1}>
                      <CheckOutlined style={{color:'green',fontSize:18}}/>
                      </Col>
                      <Col span={23} style={{color:'#5c5c5c',fontSize:'14px',fontWeight:'500'}}>{item.description}</Col>
                    </Row>
                  ))}
              </div>
        </div>
        <div className="container">
          <h1 className="font30 extraBold text-uppercase" style={{ padding: "35px 0" }}>Testing & Commissioning</h1>
          <div className='row banner-subpage slider-section'>
          <Fade top>
                <HeaderInfo>
                  <p className="font18 lineHeight30 mb-4">
                  "Protection study and relay setting coordination."
                  "Testing of main and backup protection relays for transformers, feeder lines, busbar, etc."																		
                   Testing of Various electrical equipments like capacitor bank, shunt reactors, Switch gears (Conventional & GIS type), Auto Reclosures, Ring Main Units, Protection Panels (all voltage levels), Transformers (Power, Distribution, Instrumentation), Power Cables, Conductors, AC/DC UPS Systems, Power station Batteries, Lightning protection system, LV Panels, AMF Panel, SMDBs, Capacitor Panels, Distribution Panels and Generators.																		

                  </p>
                </HeaderInfo>
              </Fade>
              </div>
        </div> */}
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;