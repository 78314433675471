import { Row, Col, Carousel } from 'antd';
import thirdImage from '../../assets/img/slider-2.jpg'
import styled from "styled-components";
import developerImg from "../../assets/img/rightArrow.png";
import Service from '../../services/Service';
import { useHistory ,useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function ViewService() {
    const history = useHistory();    
    const location = useLocation()
    const [serviceData, setServiceData] = useState({})
const Params = new URLSearchParams(location.search);
    useEffect(() => {
        getServiceData()
    }, [Params.get('id')])
    
    const getServiceData = () => {
        Service.getServiceList({ serviceId: Params.get('id') }).then(async res => {
            console.log(res,'res dataaaaaaaa');
            setServiceData(res && res.length > 0 ? res[0] : {})
        }).catch(e => {
            setServiceData({})
            console.log(e)
        })
    }


    const selectService = (code) =>{
        history.push(`/serviceView?code=${code}`)
    }

    return (
        <>
            <div style={{backgroundImage:"url("+`${serviceData?.url||''}`+")",height:450, backgroundSize: 'cover',backgroundRepeat: 'no-repeat', }}>
               <div style={{width:'100%',height:'100%',background:'black',opacity:0.5,display:'flex',justifyContent:'center',alignItems:'center',color:'#fff'}}>
                <h1 className="corousal-header"><strong>{serviceData?.subject||''}</strong></h1>
                </div>
                {/* <div className="col-md-6 textRight">
                <img src={bannerImg} className="img-fluid allapps-banner"/>
              </div> */}
            </div>
            <div className="container  my-5 content-wrapper" >
                <h3>{serviceData?.subject||''}</h3>
                <Row style={{ fontSize: 16, marginTop: 15 }}>
                    <Col className='viewProject' xs={24} sm={24} md={24} lg={24} xl={24} dangerouslySetInnerHTML={{ __html: serviceData?.description || '' }} />
                </Row>
                
            </div>
        </>
    )
}
const List = styled.li`
    color:#DB373E;
    padding: 4px 0;
    font-weight: bold;
`;