import MainService from './MainService'
var Service = {
    create(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'createService';
            $data.method = 'post';
            $data.data = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    findAll(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'getService';
            $data.method = 'get';
            $data.params = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    getServiceList(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'getServiceList';
            $data.method = 'get';
            $data.params = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    findAllWithUrl(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'getServiceWithUrl';
            $data.method = 'get';
            $data.params = value;
            MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    },
    findOne(value) {
        return new Promise(function (resolve, reject) {
            let $data = {};
            $data.url = 'getOneService/' + value.id
            $data.method = 'get';
            MainService.getResult($data)
                .then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error);
                })
        });

    }
};
export default Service;