import { Button, Divider, Select, Col, ConfigProvider, List, Form, Input, Row, message } from 'antd';

import React, { useState, useEffect, useRef } from 'react';
import ProjectService from '../../services/ProjectService';
import DocumentService from '../../services/DocumentService'
import { useHistory, useLocation } from 'react-router-dom';
import Icon, { CloseOutlined } from '@ant-design/icons';
import TextEditor from '../TextArea/TextArea'


const { Option } = Select; // Add this line to import Option
// var imageId = []
export default function AddProject() {
    const navigate = useHistory()
    const options = [
        { value: 'Open', label: 'Open' },
        { value: 'Completed', label: 'Completed' }
    ];
    const [ProjectForm] = Form.useForm();
    const [imageSrc, setImageSrc] = useState([])
    const [fileName, setFileName] = useState(null)
    // const [imageId, setImageId] = useState([])
    const [imageId, setImageId] = useState([]);

    // const imageId = []
    const [descData, setDescData] = useState(null)
    const location = useLocation()
    const Params = new URLSearchParams(location.search);
    const [file, setFile] = useState({
        name: null
    });
    const [formValues, setFormValues] = useState({
        subject: null,
        description: null
    });
    const fileInputRef = useRef(null);
    const triggerFileInput = () => {
        fileInputRef.current.click();
    }
    const save = (value) => {
        value.attachments = imageId
        if (Params.get('id')) {
            value.id = Params.get('id')
        }
        ProjectService.create(value).then(res => {
            if (res && res.data && res.data.data) {
                navigate.push(`/viewProject2?id=${res.data.data.id}`)
                message.success("Save Successfully")
            }
            else {
                navigate.push(`/viewProject2?id=${Params.get('id')}`)
                message.success("Update Successfully")

            }
        }).catch(e => {
            message.error("Something Went Wrong")
            console.log(e)
        })


    }
    useEffect(() => {
        if (Params.get('id')) {
            getById(Params.get('id'))
        }

    }, [Params.get('type'), Params.get('id')])
    const removeImage = (index) =>{
        // const newImageIds = [...imageId];
        // imageId.splice(index, 1);
        // setImageId(newImageIds);   
        setImageId(prevImageId => {
            const newImageId = [...prevImageId];
            newImageId.splice(index, 1);
            return newImageId;
        });
        
        const newImageSrc = [...imageSrc];
        newImageSrc.splice(index, 1);
        setImageSrc(newImageSrc);
    }
    const getById = (id) => {
        ProjectService.findOne({ id: id }).then(async res => {
            ProjectForm.setFieldsValue(res)
            // setImageId(res && res.attachments ? res.attachments : [])
            imageId = res && res.attachments ? res.attachments : []
            setImageSrc(res && res.urlData ? res.urlData : [])

        }).catch(e => {
            console.log(e)
        })
    }

    const handleFileUpload = async (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile !== undefined) {
            const fr = new FileReader();
            fr.readAsDataURL(selectedFile);

            fr.addEventListener('load', async (loadEvent) => {
                const dataUrl = loadEvent.target.result;
                const $json = {
                    doc_name: selectedFile.name,
                    file_name: selectedFile.name,
                    content_type: selectedFile.type,
                    active: 1,
                    data: dataUrl
                };

                try {
                    const res = await DocumentService.upload($json);
                    const imageData = await DocumentService.imageProfile({ id: parseInt(res.id) });
                    setImageSrc(prevImageSrc => [...prevImageSrc, imageData]);
                    // alert(res.id)
                    imageId.push(res.id)
                    // setImageId(prevImageId => [...prevImageId, res.id]);
                    setImageId(prevImageId => [...prevImageId, res.id]);

                } catch (error) {
                    console.error(error);
                }
            });
        }
    };


    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#364ea2',
                    },
                    components: {
                        Tabs: {
                            itemActiveColor: '#1677ff',
                        },
                        Progress: {
                            circleTextFontSize: '0.666666666666667em',
                        },
                        Tag: {
                            defaultColor: ''
                        }
                    }
                }
                }
            >
                <Row>
                    <Col span={24}>
                        <h3>Add Project</h3>
                        <CloseOutlined onClick={() => { navigate.push('/listproject2') }} style={{ float: 'right', cursor: 'pointer' }} />

                    </Col>
                </Row>
                <Divider plain></Divider>
                <Form
                    name="projectForm"
                    onFinish={save}
                    autoComplete="off"
                    form={ProjectForm}
                    layout="vertical"
                >

                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                            <Form.Item
                                name="subject"
                                label={<div className='Label'>Name</div>}
                                rules={[{ required: true, message: 'Project Name Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                            <Form.Item
                                name="stage"
                                label={<div className="Label">Stage</div>}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    size="large"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "").toLowerCase().includes(input)
                                    }
                                >
                                    {options.map(option => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                            <Form.Item
                                name="contract_no"
                                label={<div className='Label'>Contract / Tender No</div>}
                                rules={[{ required: true, message: 'Contract / Tender No Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0} style={{ width: '100%' }}>
                    <Col xs={22} sm={22} md={10} lg={10} xl={10}>
                            <Form.Item
                                name="loaction"
                                label={<div className='Label'>Location</div>}
                                rules={[{ required: true, message: 'Loaction Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                        <Col xs={22} sm={22} md={10} lg={10} xl={10} style={{marginLeft:'4px'}}>
                            <Form.Item
                                name="project_value"
                                label={<div className='Label'>Value</div>}
                                rules={[{ required: true, message: 'Value Is Required' }]}
                            >
                                <Input type="text" showReadOnly={false} size="default" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={0} style={{ width: '100%' }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item
                                name="description"
                                label={<div className='Label'>Description</div>}
                                rules={[{ required: true, message: 'Description Is Required' }]}
                            >
                                <TextEditor form={ProjectForm} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                            <div className='col-12'>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    accept=".jpg,.jpeg,.png"
                                    style={{ display: 'none' }}
                                    onChange={handleFileUpload}
                                />
                            </div>
                            <div className='col-12'>
                                <div className='uplodFile' onClick={triggerFileInput}>
                                    <div className='upldImg'></div>
                                    <div style={{ color: '#f38a50', fontSize: '15px', fontWeight: 500 }}>Upload Your File</div>
                                </div>
                                {/* {imageSrc && <img style={{ width: '15%', marginTop: 12 }} src={imageSrc} alt="John" />} */}
                            </div>

                        </Col>
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
                        {imageSrc && imageSrc.length > 0 ? (
                            imageSrc.map((key, index) => (
                                <Col span={6} style={{ height: 250 }}>
                                    <img src={key} alt="" style={{ width: '100%', height: '100%' }} />
                                    <CloseOutlined
                                        onClick={()=>removeImage(index)}
                                        style={{
                                        position: 'absolute',
                                        top: -24,
                                        right: -10,
                                        fontSize: '16px',
                                        cursor: 'pointer',
                                        background: 'white',
                                        borderRadius: '50%',
                                        padding: '2px'
                                        }}
                                    />
                                </Col>
                            ))
                        ) : <></>}

                    </Row>
                    <Row style={{ marginRight: 50 }}>

                        <Col span={24} align="end" style={{ marginTop: 10 }}>
                            <Form.Item wrapperCol={{ offset: 1 }}>
                                <Button style={{ marginRight: '5px' }} onClick={() => { navigate.push(`/listproject2`) }} htmlType="button">
                                    Cancel
                                </Button>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </ConfigProvider>
        </>
    )
}